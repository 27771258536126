import { useState } from "react";
import Logo from "../../assets/drukrewa.png";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log("FormData: ", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.password ) {
      toast.error("Please fill in all fields");
      return;
    } else if (!formData.email) {
      toast.error("Please enter your email address");
      return;
    } else if (!formData.password) {
      toast.error("Please enter your Password");
      return;
    } else if (!formData.name){
        toast.error("Please enter your Name")
    }

    if (formData.email && formData.password && formData.name) {
      console.log("send login data", formData.name, formData.email, formData.password);

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API}/user/signup`,
          formData
        );
        console.log("register response", response);

        if (response.data) {
          console.log("User logged in");
          window.localStorage.setItem("auth", JSON.stringify(response.data));
          window.localStorage.setItem("user", "created");
          toast.success("Register Successful, Please Login");
          navigate("/user/login");
        }
      } catch (err) {
        console.log("catchblock error", err);
        if (err.response.status === 400) toast.error(err.response.data);
      }
    }
  };

  return (
    <div className="buddha2 h-screen w-screen">
      <div className="rounded-md flex flex-col justify-center items-center">
        <Link className="flex justify-center items-center py-10" to={"/"}>
          <img src={Logo} alt="" className="w-24 h-auto" />
        </Link>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-1/3 h-[70vh] pt-20 sm:w-full"
        >
          <div className="flex flex-col px-5 gap-4">
            <input
              type="text"
              name="name"
              className="m-3 border-b border-gray-500 bg-transparent"
              placeholder="Enter Username"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              className="m-3 border-b border-gray-500 bg-transparent "
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              className="m-3 border-b border-gray-500 bg-transparent"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleChange}
            />
            <div className="w-full flex justify-center items-center">
              <div
                className="rounded-full border border-white"
                to={"/user/register"}
              >
                <button
                  className="py-2 px-6 uppercase cta-button"
                  type="submit"
                  disabled={!formData.email || !formData.password}
                >
                  <span
                    className={`hover:text-black relative z-[100] ${
                      !formData.email || !formData.password || !formData.name
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                  >
                    Submit
                  </span>
                </button>
              </div>
            </div>
            <small className="text-white">Already have an account? <Link to={"/user/login"} className="font-bold text-white text-md underline">Log In</Link></small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
