import NavBarTwo from "../Components/NavBarTwo"
import updatedData from "../data/data"
import Card from "../Components/Card"
import Footer from "../Components/Footer"
import { Link } from "react-router-dom"
import Tilt from "react-parallax-tilt"
import { useEffect, useState } from "react"


const Hotels = () => {
  const[data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const result = await updatedData;
      setData(result);
    }
    fetchData();
  },[])

  const thimphu = data.filter((card) => card.type ==="hotel" && card.location ==="Thimphu")
  thimphu.sort((a,b) => b.reviews- a.reviews)

  
  const paro = data.filter((card) => card.type ==="hotel" && card.location ==="Paro")
  paro.sort((a,b) => b.reviews- a.reviews)

  
  const punakha = data.filter((card) => card.type ==="hotel" && card.location ==="Punakha")
  punakha.sort((a,b) => b.reviews- a.reviews)

  
  const wangdue = data.filter((card) => card.type ==="hotel" && card.location ==="Wangdue Phodrang")
  wangdue.sort((a,b) => b.reviews- a.reviews)

  
  const bumthang = data.filter((card) => card.type ==="hotel" && card.location ==="Bumthang")
  bumthang.sort((a,b) => b.reviews- a.reviews)

  
  const lhuntse = data.filter((card) => card.type ==="hotel" && card.location ==="Lhuntse")
  lhuntse.sort((a,b) => b.reviews- a.reviews)

  
  const pling = data.filter((card) => card.type ==="hotel" && card.location ==="Phuentsholing")
  pling.sort((a,b) => b.reviews- a.reviews)

  
  return (
    <div className="sm:overflow-x-hidden">
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4 ">
        <div className="px-8 py-20 bg-white sm:px-4">
          <h1 className="text-4xl font-bold text-center pb-16 uppercase">
            Hotels
          </h1>
          <section className="pb-16 ">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Thimphu
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                  {thimphu.map((card, index) => (
                    <Card key={index} data={card} show={"hidden"} />
                  ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">Paro</h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {paro.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Punakha
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {punakha.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Wangdue Phodrang
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {wangdue.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Bumthang
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {bumthang.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Lhuntse
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {lhuntse.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Phuentsholing
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {pling.map((card, index) => (
                  <Card key={index} data={card} show={"hidden"} />
                ))}
              </div>
            </div>
          </section>
        </div>
        <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
          <div className="container mx-auto text-center">
            <h2 className="text-white text-4xl font-semibold mb-4 sm:text-xl">
              So start your trip with us right here right now
            </h2>
            <p className="text-white text-sm mb-6 sm:text-xs">
              Book your adventure now and explore Bhutan
            </p>
            <Link className="" to={"mailto:drukrewa@gmail.com"}>
              <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                <span className="hover:text-black relative z-[100]">
                  Book Now
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Hotels