import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { Rating } from "@mui/material";
import {toast} from "react-toastify"

const ContactForm = () => {

  const [ratingValue, setRatingValue] =useState(0)
  const [subject, setSubject] = useState("");
  console.log("subject", subject)

    const handleChangeRatingValue = (event, newValue) => {
      setRatingValue(newValue);
    };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email:"",
    phone: null,
    message:""
  })

   const [showRating, setShowRating] = useState(false); // state to manage visibility of rating component

   const handleRadioChange = (event) => {
    const selectedSubject = event.target.value;
    setSubject(selectedSubject);

     if (event.target.value === "feedback") {
       setShowRating(true);
     } else {
       setShowRating(false);
     }
   };

   const handleSubmit = async (event) => {
     event.preventDefault();

     console.log("in handle submit")

     let url;
     const data = {
       firstName: formData.firstname,
       lastName: formData.lastname,
       email: formData.email,
       phoneNumber: formData.phone,
       message: formData.message,
     };

     if (subject === "feedback") {
       url = `${process.env.REACT_APP_API}/feedbacks`; // replace with your feedback API URL
       data.rating = ratingValue;
     } else {
       url = `${process.env.REACT_APP_API}user/email`; // replace with your general inquiry API URL
       data.subject = "General Inquiry"
     }


     console.log("sample data", data)

     try {
       const response = await fetch(url, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(data),
       });

       if (response.ok){
        toast.success("Thank you for the feeback.")
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: null,
          message: "",
        });
       }

       if (!response.ok) {
        toast.error("Try Again!")
         throw new Error("Something went wrong");
       }

       const responseData = await response.json();

       // handle response data here
     } catch (error) {
       console.error(error);
       // handle error here
     }
   };

   const handleChange = (event) => {
     setFormData({
       ...formData,
       [event.target.name]: event.target.value,
     });
   };

  return (
    <div className="p-4 rounded-md bg-white flex sm:flex-col">
      <div className="w-1/3 contact-page rounded-sm pb-40 sm:w-full">
        <div className="pt-10 pb-20 px-10">
          <h1 className="font-bold text-white text-3xl sm:text-center">Contact Information</h1>
          <p className="text-white font-thin sm:text-center">
            Let us know if you have any facing any inquiries
          </p>
        </div>
        <div className="flex flex-col px-10 gap-10 sm:px-4 sm:gap-4">
          <div className="flex gap-8">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5002 11.5331H22.5002C22.5002 6.40309 18.6272 2.53409 13.4902 2.53409V4.53409C17.5522 4.53409 20.5002 7.47709 20.5002 11.5331Z"
                fill="white"
              />
              <path
                d="M13.4998 8.53409C15.6028 8.53409 16.4998 9.43109 16.4998 11.5341H18.4998C18.4998 8.30909 16.7248 6.53409 13.4998 6.53409V8.53409ZM16.9218 13.9771C16.7296 13.8024 16.4771 13.7093 16.2176 13.7173C15.9581 13.7253 15.7118 13.8339 15.5308 14.0201L13.1378 16.4811C12.5618 16.3711 11.4038 16.0101 10.2118 14.8211C9.01979 13.6281 8.65879 12.4671 8.55179 11.8951L11.0108 9.50109C11.1972 9.32022 11.3059 9.07391 11.314 8.8143C11.322 8.55468 11.2287 8.30213 11.0538 8.11009L7.35879 4.04709C7.18384 3.85445 6.94067 3.73759 6.68095 3.72135C6.42122 3.7051 6.16539 3.79075 5.96779 3.96009L3.79779 5.82109C3.6249 5.9946 3.52171 6.22554 3.50779 6.47009C3.49279 6.72009 3.20679 12.6421 7.79879 17.2361C11.8048 21.2411 16.8228 21.5341 18.2048 21.5341C18.4068 21.5341 18.5308 21.5281 18.5638 21.5261C18.8083 21.5124 19.0391 21.4088 19.2118 21.2351L21.0718 19.0641C21.2413 18.8666 21.3271 18.6109 21.311 18.3511C21.295 18.0914 21.1783 17.8482 20.9858 17.6731L16.9218 13.9771Z"
                fill="white"
              />
            </svg>
            <span className="text-white text-start">+975 177 60 015</span>
          </div>
          <div className="flex gap-8">
            <svg
              width="21"
              height="17"
              viewBox="0 0 21 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 0.534088H0.5V16.5341H20.5V0.534088ZM18.5 4.53409L10.5 9.53409L2.5 4.53409V2.53409L10.5 7.53409L18.5 2.53409V4.53409Z"
                fill="white"
              />
            </svg>

            <span className="text-white text-start">drukrewa@gmail.com</span>
          </div>
          <div className="flex gap-8">
            <svg
              width="17"
              height="22"
              viewBox="0 0 17 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.50001 0.0340881C6.31276 0.0366685 4.21584 0.906691 2.66923 2.45331C1.12261 3.99992 0.252589 6.09684 0.250009 8.28409C0.247389 10.0715 0.831244 11.8104 1.91201 13.2341C1.91201 13.2341 2.13701 13.5303 2.17376 13.5731L8.50001 21.0341L14.8293 13.5693C14.8623 13.5296 15.088 13.2341 15.088 13.2341L15.0888 13.2318C16.169 11.8088 16.7526 10.0707 16.75 8.28409C16.7474 6.09684 15.8774 3.99992 14.3308 2.45331C12.7842 0.906691 10.6873 0.0366685 8.50001 0.0340881ZM8.50001 11.2841C7.90666 11.2841 7.32665 11.1081 6.8333 10.7785C6.33995 10.4489 5.95543 9.98032 5.72837 9.43214C5.50131 8.88396 5.4419 8.28076 5.55765 7.69882C5.67341 7.11687 5.95913 6.58232 6.37869 6.16277C6.79825 5.74321 7.33279 5.45749 7.91474 5.34173C8.49668 5.22598 9.09988 5.28539 9.64806 5.51245C10.1962 5.73951 10.6648 6.12403 10.9944 6.61738C11.3241 7.11073 11.5 7.69074 11.5 8.28409C11.499 9.07943 11.1826 9.84192 10.6202 10.4043C10.0578 10.9667 9.29535 11.2831 8.50001 11.2841Z"
                fill="white"
              />
            </svg>

            <span className="text-white text-start w-1/2">
              Flat No.301, Building No 47A, Norzin Lam, Thimphu, Bhutan
            </span>
          </div>
        </div>
      </div>
      <form className="w-2/3 p-8 flex flex-col gap-16 sm:w-full sm:gap-8 sm:p-4" onSubmit={handleSubmit}>
        <div className="flex gap-20 w-full sm:gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="firstname" className="sm:text-sm">First Name</label>
            <input
              type="text"
              className="border-b-2 border-b-[#b8b8b8] focus:outline-none"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="lastname" className="sm:text-sm">Last Name</label>
            <input
              type="text"
              className="border-b-2 border-b-[#b8b8b8] focus:outline-none"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex gap-20 w-full sm:gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="email" className="sm:text-sm">Email</label>
            <input
              type="text"
              className="border-b-2 border-b-[#b8b8b8] focus:outline-none"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="phonenumber" className="sm:text-sm">Phone Number (Optional)</label>
            <input
              type="number"
              className="border-b-2 border-b-[#b8b8b8] focus:outline-none"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <h1 className="font-bold text-xl pb-2 sm:text-base">Select Subject:</h1>
          <div className="flex gap-12">
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                name="subject"
                id=""
                value={"general"}
                onChange={handleRadioChange}
              />
              <label htmlFor="general" className="sm:text-sm">General Inquiry</label>
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                name="subject"
                id=""
                value={"feedback"}
                onChange={handleRadioChange}
              />
              <label htmlFor="feedback" className="sm:text-sm">Feedback</label>
            </div>
          </div>
        </div>
        {showRating && (
          <div className="flex flex-col">
            <h1 className="font-bold py-2 sm:text-base">
              Please rate us your experiences with us.
            </h1>
            <div>
              <Rating
                value={ratingValue}
                onChange={handleChangeRatingValue}
                precision={0.5}
                size="large"
              />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2 w-full">
          <label htmlFor="message" className="sm:text-sm">Message</label>
          <textarea
            id=""
            cols="30"
            rows="4"
            className="border-b-2 border-b-[#b8b8b8] focus:outline-none"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="sm:flex sm:justify-center sm:items-center">
          <button
            className="cta-button bg-black text-white px-6 py-2 rounded-full uppercase text-lg font-sans hover:bg-white hover:text-black hover:transition-all hover:duration-1000 border hover:border-black "
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            type="submit"
          >
            <span className="hover:text-black relative z-[100]">Send</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
