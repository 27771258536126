import React, { useState } from "react";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import { AddCircle, AddCircleOutline, RemoveCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import updatedData from "../../data/data";
import Card from "../Card";
import {CircularProgress} from "@mui/material";

function Tours() {
  const dbName = window.localStorage.getItem("dbName");
  const [data, setData] = useState([])
  const [backendData, setBackendData] = useState([])
  const [filteredPackages, setFilterPackages] = useState([])
  const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      async function fetchData() {
        const result = await updatedData;
        setData(result);
      }
      fetchData();
    }, []);
    console.log("dbname from session is", dbName);

    const packagesData = data.filter((card) => card.type === "packages");
  
  const initialPackagesState = [
    {
      dbName: "",
      images: [],
      name: "",
      location: "",
      days: [{ images: [], caption: [], content: "" }],
      type: "",
    },
  ];
  const [packages, setPackages] = useState([
    {
      dbName: "",
      images: [],
      name: "",
      location: "",
      days: [{ images: [], caption: [], content: "" }],
      type: "",
    },
  ]);

  const handleAddDay = (index) => {
    const newPackages = [...packages];
    newPackages[index].days.push({ images: [], caption: [], content: "" });
    setPackages(newPackages);
  };

  const handleRemoveDay = (pkgIndex, dayIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days.splice(dayIndex, 1);
    setPackages(newPackages);
  };

  const handleInputChange = (pkgIndex, field, value) => {
    const newPackages = [...packages];
    newPackages[pkgIndex][field] = value;
    setPackages(newPackages);
  };

  const handleDayInputChange = (pkgIndex, dayIndex, field, value) => {
    const newPackages = [...packages];
    if (field === "images" || field === "caption") {
      newPackages[pkgIndex].days[dayIndex][field].push(value);
    } else {
      newPackages[pkgIndex].days[dayIndex][field] = value;
    }
    setPackages(newPackages);
  };

  const handleAddThumbImage = (pkgIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].images.push("");
    setPackages(newPackages);
  };

  const handleRemoveThumbImage = (pkgIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].images.splice(pkgIndex, 1);
    setPackages(newPackages);
  };

  const handleImageChange = (pkgIndex, imageIndex, newValue) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].images[imageIndex] = newValue;
    setPackages(newPackages);
  };

  const handleDayImageChange = (pkgIndex, dayIndex, imageIndex, newValue) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].images[imageIndex] = newValue;
    setPackages(newPackages);
  };

  const handleDayCaptionChange = (
    pkgIndex,
    dayIndex,
    captionIndex,
    newValue
  ) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].caption[captionIndex] = newValue;
    setPackages(newPackages);
  };

  const handleAddDayImage = (pkgIndex, dayIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].images.push("");
    setPackages(newPackages);
  };

  const handleAddDayCaption = (pkgIndex, dayIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].caption.push("");
    setPackages(newPackages);
  };

  const handleRemoveDayImage = (pkgIndex, dayIndex, imageIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].images.splice(imageIndex, 1);
    setPackages(newPackages);
  };

  const handleRemoveDayCaption = (pkgIndex, dayIndex, captionIndex) => {
    const newPackages = [...packages];
    newPackages[pkgIndex].days[dayIndex].caption.splice(captionIndex, 1);
    setPackages(newPackages);
  };

  const validatePackages = (packages) => {
    for (let pkg of packages) {
      if (!pkg.name || !pkg.location || !pkg.type) {
        toast.error("Package name, location, and type must not be empty.");
        return false;
      }

      for (let day of pkg.days) {
        if (day.images.length !== day.caption.length) {
          toast.error(
            "Each day must have the same number of images and captions."
          );
          return false;
        }

        for (let image of day.images) {
          if (!image) {
            toast.error("Image URLs must not be empty.");
            return false;
          }
        }

        for (let caption of day.caption) {
          if (!caption) {
            toast.error("Captions must not be empty.");
            return false;
          }
        }
      }
    }
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(packages);
    if (!validatePackages(packages)) {
      return;
    } else {
      createPackages();
    }
  };

  const createPackages = async () => {
    for (const pkg of packages) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/packages/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(pkg),
          }
        );
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }

        const data = await response.json();
        toast.success("Package Added Successfully");
        setPackages(initialPackagesState);
        console.log(data);
      } catch (error) {
        console.error("Failed to create package:", error);
      }
    }
  };

  return (
    <div className="overflow-scroll h-screen">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col pt-12 w-full px-20 "
      >
        <h1 className="text-center text-4xl font-bold uppercase pb-8">
          Add Packages
        </h1>
        {packages.map((pkg, pkgIndex) => (
          <div key={pkgIndex} className="flex flex-col">
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <label htmlFor="dbname" className="font-medium text-lg py-2">
                  Reviews Name
                </label>
                <input
                  type="text"
                  value={pkg.dbName}
                  onChange={(e) =>
                    handleInputChange(pkgIndex, "dbName", e.target.value)
                  }
                  placeholder="DB Name"
                  className="border-b border-gray-500"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="name" className="font-medium text-lg py-2">
                  Package Name
                </label>
                <input
                  type="text"
                  value={pkg.name}
                  onChange={(e) =>
                    handleInputChange(pkgIndex, "name", e.target.value)
                  }
                  placeholder="Name"
                  className="border-b border-gray-500"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="location" className="font-medium text-lg py-2">
                  Location
                </label>
                <input
                  type="text"
                  value={pkg.location}
                  onChange={(e) =>
                    handleInputChange(pkgIndex, "location", e.target.value)
                  }
                  placeholder="Location"
                  className="border-b border-gray-500"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="type" className="font-medium text-lg py-2">
                  Type
                </label>
                <input
                  type="text"
                  value={pkg.type}
                  onChange={(e) =>
                    handleInputChange(pkgIndex, "type", e.target.value)
                  }
                  placeholder="Type"
                  className="border-b border-gray-500"
                />
              </div>
            </div>

            <div className="flex flex-col py-8">
              <label htmlFor="images" className="font-medium text-lg py-2">
                Thumbnail Images
              </label>
              {pkg.images.map((image, imageIndex) => (
                <div key={imageIndex}>
                  <input
                    type="text"
                    value={image}
                    onChange={(e) =>
                      handleImageChange(pkgIndex, imageIndex, e.target.value)
                    }
                    placeholder="Image Link"
                    className="border-b border-gray-500"
                  />
                  <Tooltip title={"Remove Thumbnail Image"} arrow>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveThumbImage(pkgIndex);
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Tooltip>
                </div>
              ))}
              <div>
                <Tooltip title={"Add Thumbnail Image"} arrow>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddThumbImage(pkgIndex);
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {pkg.days.map((day, dayIndex) => (
              <div
                key={dayIndex}
                className="flex flex-col pb-4 border-b border-gray-500/50"
              >
                <div className="font-medium text-lg py-2">
                  Day {dayIndex + 1}
                </div>

                <div className="flex w-full">
                  <div className="flex flex-col w-4/5">
                    <textarea
                      cols={40}
                      rows={6}
                      value={day.content}
                      onChange={(e) =>
                        handleDayInputChange(
                          pkgIndex,
                          dayIndex,
                          "content",
                          e.target.value
                        )
                      }
                      placeholder="Content"
                      className="border-b border-gray-500"
                    ></textarea>

                    <div className="flex w-full">
                      <div className="w-1/2">
                        <h1 className="font-medium text-lg py-2">Images</h1>
                        {packages[pkgIndex].days[dayIndex].images.map(
                          (image, imageIndex) => (
                            <div key={imageIndex}>
                              <input
                                type="text"
                                value={image}
                                onChange={(e) =>
                                  handleDayImageChange(
                                    pkgIndex,
                                    dayIndex,
                                    imageIndex,
                                    e.target.value
                                  )
                                }
                                placeholder="Image URL"
                                className="w-4/5 border-b border-gray-500"
                              />
                              <Tooltip title={"Remove Day Image"} arrow>
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveDayImage(
                                      pkgIndex,
                                      dayIndex,
                                      imageIndex
                                    );
                                  }}
                                >
                                  <RemoveCircle />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        )}
                        <div>
                          <Tooltip title={"Add Day Image"} arrow>
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddDayImage(pkgIndex, dayIndex);
                              }}
                            >
                              <AddCircle />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="w-1/2">
                        <h1 className="font-medium text-lg py-2">Caption</h1>
                        {packages[pkgIndex].days[dayIndex].caption.map(
                          (caption, captionIndex) => (
                            <div key={captionIndex}>
                              <input
                                type="text"
                                value={caption}
                                onChange={(e) =>
                                  handleDayCaptionChange(
                                    pkgIndex,
                                    dayIndex,
                                    captionIndex,
                                    e.target.value
                                  )
                                }
                                placeholder="Caption"
                                className="w-4/5 border-b border-gray-500"
                              />
                              <Tooltip title={"Add Caption"} arrow>
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveDayCaption(
                                      pkgIndex,
                                      dayIndex,
                                      captionIndex
                                    );
                                  }}
                                >
                                  <RemoveCircle />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        )}
                        <div>
                          <Tooltip title={"Remove Caption"} arrow>
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddDayCaption(pkgIndex, dayIndex);
                              }}
                            >
                              <AddCircle />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <Tooltip title={"Remove Day"} arrow>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveDay(pkgIndex, dayIndex);
                        }}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center py-4">
              <Tooltip title={"Add Day"} arrow>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddDay(pkgIndex);
                  }}
                >
                  <AddCircleOutline fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))}
        <div className="flex justify-center items-center">
          <button
            className="cta-button bg-black text-white px-6 py-2 rounded-full uppercase text-lg font-sans hover:bg-white hover:text-black hover:border-black hover:transition-all hover:duration-1000 border"
            type="submit"
          >
            <span className="hover:text-black relative z-[100]">Submit</span>
          </button>
        </div>
      </form>
      <h1 className="pt-20 pl-20 text-3xl uppercase font-bold">Available Packages</h1>
      <div className="flex flex-wrap overflow-hidden items-center justify-center gap-y-4 gap-16 pt-16">
        {packagesData.map((card, index) => (
          <Card
            key={index}
            data={card}
            showMore={true}
            link={"/admin/tour/update"}
          />
        ))}
      </div>
    </div>
  );
}

export default Tours;
