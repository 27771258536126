import logo from "../assets/drukrewa.png"
import { Link, useLocation } from "react-router-dom"
import {motion} from "framer-motion"
import { Hotels, Destination, Package, Visa } from "./NavBarLogos"


const Navbar = () => {

const LocalStorage = window.localStorage.getItem("user")

const Button = () => {
  if (LocalStorage === "created") {
    return (
      <Link className="rounded-full border border-black" to={"/user/login"}>
        <button className="py-2 px-6 uppercase signup-button ">
          <span className="hover:text-white relative z-[100]">Log In</span>
        </button>
      </Link>
    );
  }else if (LocalStorage === "logged in"){
      return (
        <div className="rounded-full border border-transparent bg-transparent">
          <div className="py-2 px-6  uppercase bg-transparent">
            <span className="relative z-[100] text-transparent cursor-default">
              Sign Up
            </span>
          </div>
        </div>
      );
  }else{
      return (
        <Link className="rounded-full border border-black" to={"/user/register"}>
          <button className="py-2 px-6 uppercase signup-button ">
            <span className="hover:text-white relative z-[100]">Sign Up</span>
          </button>
        </Link>
      );
  }
}

const location = useLocation();
  return (
    <motion.div
      className="flex flex-col w-screen py-2 fixed bg-white z-[9999] shadow-lg"
      initial={{ y: -50, opacity: 0.5 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.nav className="flex flex-row justify-between items-center border-b-[#b8b8b8] border-b pb-2 px-16 sm:px-4">
        <Link to={"/"} className="sm:pr-4">
          <img src={logo} alt="drukrewa logo" className=" w-16 h-auto" />
        </Link>
        <motion.div className="flex gap-20  font-regular sm:gap-6 sm:justify-center sm:items-center">
          <Link
            className={`uppercase sm:text-[6px] sm:capitalize ${
              location.pathname === "/" ? "border-b-2  border-b-black" : " "
            }`}
            to={"/"}
          >
            Home
          </Link>
          <Link
            className={`uppercase sm:text-[6px] sm:capitalize ${
              location.pathname === "/bhutan"
                ? "border-b-2 border-b-black"
                : " "
            }`}
            to={"/bhutan"}
          >
            Bhutan
          </Link>
          <Link
            className={`uppercase sm:text-[6px] sm:capitalize ${
              location.pathname === "/drukrewa"
                ? "border-b-2 border-b-black"
                : " "
            }`}
            to={"/drukrewa"}
          >
            Druk Rewa
          </Link>
          <Link
            className={`uppercase sm:text-[6px] sm:capitalize ${
              location.pathname === "/sustainability"
                ? "border-b-2 border-b-black"
                : " "
            }`}
            to={"/sustainability"}
          >
            Sustainability
          </Link>
          <Link
            className={`uppercase sm:text-[6px] sm:capitalize ${
              location.pathname === "/contactus"
                ? "border-b-2 border-b-black"
                : " "
            }`}
            to={"/contactus"}
          >
            Contact Us
          </Link>
        </motion.div>
        {<Button />}
      </motion.nav>
      <motion.nav className="flex justify-center items-center gap-16 pt-4 pb-1">
        <Link
          className="flex flex-col gap-2 justify-center items-center"
          to={"/visa"}
        >
          <Visa />
          <p
            className={`${
              location.pathname === "/visa" ? "text-black" : "text-[#b8b8b8]"
            } hover:text-black text-xs`}
          >
            Visa
          </p>
        </Link>
        <Link
          className="flex flex-col gap-2 justify-center items-center"
          to={"/destinations"}
        >
          <Destination />
          <p
            className={`${
              location.pathname === "/destinations"
                ? "text-black"
                : "text-[#b8b8b8]"
            }  hover:text-black text-xs`}
          >
            Destinations
          </p>
        </Link>
        <Link
          className="flex flex-col gap-2 justify-center items-center"
          to={"/hotels"}
        >
          <Hotels />
          <p
            className={`${
              location.pathname === "/hotels" ? "text-black" : "text-[#b8b8b8]"
            }  hover:text-black text-xs`}
          >
            Hotels
          </p>
        </Link>
        <Link
          className="flex flex-col gap-2 justify-center items-center"
          to={"/packages"}
        >
          <Package />
          <p
            className={`${
              location.pathname === "/packages"
                ? "text-black"
                : "text-[#b8b8b8]"
            }  hover:text-black text-xs`}
          >
            Packages
          </p>
        </Link>
      </motion.nav>
    </motion.div>
  );
}

export default Navbar