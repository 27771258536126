import Accordion from "./Accordion";

const faqs = [
  {
    question: "What are the must-visit destinations in Bhutan?",
    answer: `Bhutan offers an array of incredible destinations. From the iconic Tiger's Nest Monastery (Paro Taktsang) to the serene Punakha Dzong and the cultural hub of Thimphu, each place has its unique charm. Our tours ensure you experience these highlights and more.`,
  },
  {
    question: "What is the best time to visit Bhutan?",
    answer: `The ideal time to visit Bhutan is during the spring (March to May) and autumn (September to November) seasons. These periods offer pleasant weather, clear skies, and vibrant landscapes, making it perfect for sightseeing and trekking.`,
  },
  {
    question: "How can I obtain a visa to visit Bhutan?",
    answer: `Bhutan regulates tourism through a system that requires visitors to book through licensed tour operators. As part of our services, we arrange the necessary permits and visas for our guests to ensure a smooth and hassle-free experience.`,
  },
  {
    question: "What cultural experiences can I expect in Bhutan?",
    answer: `Bhutan boasts a rich cultural heritage reflected in its festivals, traditional architecture, and spiritual practices. Our tours include visits to monasteries, interactions with local communities, and opportunities to witness vibrant festivals like the Paro Tsechu.`,
  },
  {
    question: "What level of physical fitness is required for Bhutan tours?",
    answer: `Bhutan offers a variety of experiences suitable for different fitness levels. While some treks demand moderate fitness, many sightseeing tours cater to all fitness levels. We customize itineraries to accommodate your preferences and physical capabilities.`,
  },
  {
    question:
      "What measures are in place for preserving Bhutan's environment and culture during tours?",
    answer: `We are committed to sustainable tourism. Our agency follows eco-friendly practices and respects local customs. We work closely with local communities, employ trained guides, and support initiatives that contribute to the conservation of Bhutan's environment and culture.`,
  },
];

const Faqs = () => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <Accordion key={index} {...faq} />
      ))}
    </div>
  );
}

export default Faqs


