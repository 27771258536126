import NavBarTwo from "../Components/NavBarTwo"
import Card from "../Components/Card";
import updatedData from "../data/data"
import Footer from "../Components/Footer";
import {Link} from "react-router-dom"
import { useEffect, useState } from "react";


const Packages = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData(){
      const result = await updatedData;
      setData(result)
    }
    fetchData()
  },[])

  const packages = data.filter((card) => card.type === "packages")
  packages.sort ((a,b) => b.reviews - a.reviews)

  const trekkingPackages = data.filter((card) => card.type === "trekking packages")
  trekkingPackages.sort((a,b) => b.reviews - a.reviews)

  const extractedData = data.map(({dbName, reviews}) => ({dbName, reviews}))
  console.log (extractedData)

  const click = async() => {
    const response = await fetch(`${process.env.REACT_APP_API}/reviews/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(extractedData),
    });
    console.log("response", response)
    
  }



  return (
    <div className="sm:overflow-x-hidden">
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4">
        <div className="px-8 py-20 bg-white sm:px-4">
          <h1 className="text-4xl font-bold text-center pb-16 uppercase">
            Packages
          </h1>
          <section className="pb-16">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Cultural Tours
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {packages.map((card, index) => (
                  <Card key={index} data={card} link={"/packages/details"} showMore={true}/>
                ))}
              </div>
            </div>
          </section>
          <section className="">
            <h2 className="font-bold tracking-tight text-[2rem] pb-3">
              Trekking Packages
            </h2>

            <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-4 gap-2">
              <div className="flex flex-wrap justify-start overflow-hidden w-full gap-y-12 gap-[6px] sm:justify-center sm:items-center">
                {trekkingPackages.map((card, index) => (
                  <Card key={index} data={card}/>
                ))}
              </div>
            </div>
          </section>
        </div>
        <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
          <div className="container mx-auto text-center">
            <h2 className="text-white text-4xl font-semibold mb-4 sm:text-xl">
              So start your trip with us right here right now
            </h2>
            <p className="text-white text-sm mb-6 sm:text-xs">
              Book your adventure now and explore Bhutan
            </p>
            <Link className="" to={"mailto:drukrewa@gmail.com"}>
              <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                <span className="hover:text-black relative z-[100]">
                  Book Now
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Packages