import React, { useEffect, useState } from 'react'
import {Users} from "./AdminIcons"

const General = () => {
    const [userData, setUserData] = useState(0)
    const [feedbackCounts, setFeebackCounts] = useState(0)
    const [drukrewaRating, setDrukRewaRatings] = useState(0)

    useEffect(() => {
fetch(`${process.env.REACT_APP_API}/user/userCounts`).then(
    response => response.json().then (data => {
        setUserData(data.data.userCount)
        console.log("userdata", data.data.userCount)
    }).catch(error => {
        console.error('error fetching data:', error)
    })
);
    }, [userData])
    
    useEffect(() => {
      Promise.all([
        fetch(`${process.env.REACT_APP_API}/feedbacks/rating`),
        fetch(`${process.env.REACT_APP_API}/feedbacks/total`),
        // add more fetch requests as needed
      ])
        .then(async ([response1, response2]) => {
          const data1 = await response1.json();
          const data2 = await response2.json();

          const drukRewaReview = data1.data;
          const totalFeedbacks = data2.data;

          if (drukRewaReview) {
            setDrukRewaRatings(drukRewaReview.toFixed(2));
          } else {
            console.log("No review found for 'drukrewa'");
          }

          if (totalFeedbacks) {
            setFeebackCounts(totalFeedbacks);
          } else {
            console.log("No count found for 'feedbacks'");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

  return (
    <div className="flex w-full gap-6 p-12">
      <div className="flex justify-between w-1/4 bg-[#b374ff] items-center pl-2 rounded-md shadow-xl">
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-white">Total Users</p>
          <h1 className="font-bold text-white text-2xl">{userData}</h1>
        </div>
        <div className="flex flex-col">
          <div className="rounded-full h-16 w-16 bg-black/10 -mb-8 -mr-12 "></div>
          <Users/>
          <div className="rounded-full h-16 w-16 bg-black/10 -mt-10"></div>
        </div>
      </div>
      <div className="flex justify-between w-1/4 bg-[#2e95e7] items-center pl-2 rounded-md shadow-xl">
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-white">Total Testimonials</p>
          <h1 className="font-bold text-white text-2xl">{feedbackCounts}</h1>
        </div>
        <div className="flex flex-col">
          <div className="rounded-full h-16 w-16 bg-black/10 -mb-8 -mr-12 "></div>
          
          <div className="rounded-full h-16 w-16 bg-black/10 -mt-10"></div>
        </div>
      </div>
      <div className="flex justify-between w-1/4 bg-[#fe8a96] items-center pl-2 rounded-md shadow-xl">
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-white">Agency Rating</p>
          <h1 className="font-bold text-white text-2xl">{drukrewaRating}</h1>
        </div>
        <div className="flex flex-col">
          <div className="rounded-full h-16 w-16 bg-black/10 -mb-8 -mr-12 "></div>
          <div className="rounded-full h-16 w-16 bg-black/10 -mt-10"></div>
        </div>
      </div>
    </div>
  );
}

export default General