import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import one from "../assets/Numbers/1.png"
import two from "../assets/Numbers/2.png";
import three from "../assets/Numbers/3.png";
import four from "../assets/Numbers/4.png";

const GNH = () => {
  const [time, setTime] = useState(0);
  const [isHovered, setIsHovered] = useState(false)
  const [bgColor, setBgColor] = useState(0.5)

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const imageArray = [
    { title: "Culture", color: `rgba( 195, 79, 166, ${bgColor})`, border:`rgba( 195, 79, 166, 0.9)` },
    { title: "Time-use", color: `rgba( 158, 116,180, ${bgColor})`, border: `rgba( 158, 116,180, 0.9)` },
    { title: "Good Governance", color: `rgba( 166, 67, 230, ${bgColor})`, border:`rgba( 166, 67, 230, 0.9)` },
    { title: "Community Vitality", color: `rgba( 121, 38, 219, ${bgColor})`, border:`rgba( 121, 38, 219, 0.9)` },
    { title: "Living Standards", color: `rgba( 87, 22, 204, ${bgColor})`, border:`rgba( 87, 22, 204, 0.9)` },
    { title: "Health", color: `rgba( 95, 92, 182, ${bgColor})`, border:`rgba( 95, 92, 182, 0.9)` },
    { title: "Education", color: `rgba( 105, 137, 172, ${bgColor})`, border:`rgba( 105, 137, 172, 0.9)` },
    { title: "Environment", color: `rgba( 103, 151, 167, ${bgColor})`, border:`rgba( 103, 151, 167, 0.9)` },
    { title: "Psychological Well-being", color: `rgba( 112, 183, 159, ${bgColor})`, border:`rgba( 112, 183, 159, 0.9)` },
  ];

  const springProps = useSpring({
    loop: { reverse: true },
    from: { transform: "rotate(0deg)"},
    to: { transform: "rotate(360deg)"},
    config: { duration: 5000 },
  });

  const numImages = 9;
  const angleStep = 360 / numImages;
  const radius = 225; // Adjust this value to change the distance of the images from the center

  const getTransform = (index) => {
    const radianAngle = (((angleStep * index) % 360) * Math.PI) / 180;
    const x = Math.cos(radianAngle) * radius;
    const y = Math.sin(radianAngle) * radius;
    return `translate(-50%, -50%) translate(${x}px, ${y}px)`;
  };


  const [show, setShow] = useState(false)

  const parentBorder = show ? "border-[10px] border-gray-500/10" : "border-[10px] border-gray-500"
  const childBorder = show ? "border-transparent !items-start" : "border-gray-500"
  const childBorder2 = show ? "border-transparent !items-end" : "border-gray-500"
  const childBorder3 = show ? "border-red-500/50 !items-end" : "border-gray-500"

  const handleMouseEnterOn = () => {
    setShow(true)
  }

  const handleMouseLeaveOn = () => {
    setShow(false)
  }

  const images = imageArray.map((image, i) => (
    <animated.div
      key={i}
      style={{
        ...springProps,
        transform: getTransform(i + time),
        position: "absolute",
        top: "50%",
        left: "50%",
        backgroundColor: isHovered === true ? image.color : image.border,
        border: "7px solid white" ,
        transition: "background-color 0.5s ease", // Add this line
        ":hover": {
          backgroundColor: image.border, // Change the background color on hover
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`w-52 h-52 flex justify-center items-center rounded-full sm:hidden`}
    >
      <p className="font-bold text-4xl text-white">{i + 1}.</p>
      <p className="text-white p-4">{image.title}</p>
    </animated.div>
  ));

  // Update the time state every frame to cause the spring props to recompute
  requestAnimationFrame(() => setTime((prevTime) => prevTime + 0.0005));

  return (
    <div className="h-screen flex items-center justify-center sm:px-40 sm:-mt-60">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "0vh",
          position: "relative",
        }}
        onMouseEnter={handleMouseEnterOn}
        onMouseLeave={handleMouseLeaveOn}
        className="bg-black"
      >
        {isHovered === false ? (
          <p
            style={{ position: "absolute" }}
            className={`rounded-full ${parentBorder} hover:overflow-visible h-[20rem] w-[20rem] justify-center items-center flex flex-col overflow-hidden font-thin uppercase hover:font-bold hover:z-10 hover:transition-all hover:duration-500`}
          >
            <div
              className={`flex w-full h-1/2 border-b-[5px] ${childBorder3} justify-center items-center`}
            >
              <div
                className={`w-1/2 h-full flex justify-center items-center border-r-[5px] ${childBorder2} pl-10 pt-4 text-right pr-1`}
                style={{
                  background: `url("${one}")`,
                  backgroundSize: "50px 50px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Good Governance
              </div>
              <div
                className={`w-1/2 h-full flex justify-center items-center border-l-[5px] ${childBorder2} pr-10 pl-1`}
                style={{
                  background: `url("${two}")`,
                  backgroundSize: "50px 50px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Sustainable Socio-economic Development
              </div>
            </div>
            <div
              className={`flex w-full h-1/2 border-t-[5px] ${childBorder} justify-center items-center`}
            >
              <div
                className={`w-1/2 h-full flex justify-center items-center border-r-[5px] ${childBorder} pl-10 text-right pr-1`}
                style={{
                  background: `url("${three}")`,
                  backgroundSize: "50px 50px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Preservation and Promotion of Culture
              </div>
              <div
                className={`w-1/2 h-full flex justify-center items-center border-l-[5px] ${childBorder} pr-4 pl-2`}
                style={{
                  background: `url("${four}")`,
                  backgroundSize: "50px 50px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                Environmental Conservation
              </div>
            </div>
          </p>
        ) : (
          <p
            style={{ position: "absolute" }}
            className="rounded-full border-[10px] border-gray-500 h-[20rem] w-[20rem] justify-center items-center flex flex-col overflow-hidden text-3xl font-bold px-4 text-center"
          >
            4 Pillars and 9 Domains of GNH
          </p>
        )}

        {images}
      </div>
    </div>
  );
};

export default GNH;
