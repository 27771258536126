import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import LoadingPage from "./Components/LoadingPage";
import Navbar from "./Components/Navbar";
import { Location } from "./Components/NavBarLogos";
import { useInView } from "react-intersection-observer";
import { Link, useLocation } from 'react-router-dom';
import EventSlider from "./Components/EventSlider";
import updatedData from "./data/data";
import Card from "./Components/Card";
import Faqs from "./Components/Faqs";
import Footer from "./Components/Footer";
import travel from './assets/travelife.png'
import abto from "./assets/abto.png"

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData ] =useState([])
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true,
  });

  // const data = DataComp()
  // Homestay

useEffect(() => {
    async function fetchData() {
      const result = await updatedData;
      setData(result)
      setIsLoading(false)
    }
    fetchData()
},[])

  console.log("data",data)

  const homestayData = data.filter((card) => card.type === "destinations");
  homestayData.sort((a, b) => b.reviews - a.reviews);
  const topFourHomestays = homestayData.slice(0, 4);

  // Hotel
  const hotelData = data.filter((card) => card.type === "hotel");
  hotelData.sort((a, b) => b.reviews - a.reviews);
  const topFourHotel = hotelData.slice(0, 4);

  // Packages
  const packagesData = data.filter((card) => card.type === "packages");
  packagesData.sort((a, b) => b.reviews - a.reviews);
  const topFourPackages = packagesData.slice(0, 4);


  return (
    <AnimatePresence>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="sm:overflow-x-hidden">
          <Navbar />
          <motion.div className="buddha w-full">
            <div className="h-screen flex flex-col px-16 sm:px-4">
              <motion.div
                className="flex justify-end pt-40 items-center gap-1"
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <Location />
                <p className="text-[#b4b4b4] sm:text-xs">
                  Buddha Dordenma, Thimphu, Bhutan
                </p>
              </motion.div>
              <div className="flex justify-start w-1/3 uppercase font-serif text-5xl sm:text-3xl text-white flex-col sm:pt-20">
                <motion.p
                  className="font-thin flex flex-col gap-4"
                  initial={{ x: -50 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 1 }}
                >
                  Unviel{" "}
                  <motion.i
                    className="font-bold"
                    initial={{ x: -100 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 1 }}
                  >
                    {" "}
                    Bhutan's{" "}
                  </motion.i>{" "}
                  Mystique
                </motion.p>
                  <Link to={"mailto:drukrewa@gmail.com"} className="sm:pt-20">
                  <motion.button
                    className="cta-button bg-black text-white px-6 py-2  rounded-full uppercase text-lg sm:text-sm font-sans hover:bg-white hover:text-black hover:transition-all hover:duration-1000"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                  >
                    <span className="hover:text-black relative z-[100]">
                      Book Now
                    </span>
                  </motion.button>
                </Link>
              </div>
              <div className="flex w-full justify-end pt-12 text-3xl sm:text-lg text-white font-serif sm:pl-20 sm:pt-40">
                <motion.p
                  className="w-1/4 text-end font-thin sm:w-full"
                  initial={{ x: 50 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 1 }}
                >
                  Immerse Yourself in{" "}
                  <motion.i
                    className="font-bold px-2 sm:text-end"
                    initial={{ x: 150 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 1 }}
                  >
                    Tranquil Traditions
                  </motion.i>{" "}
                  and
                  <motion.i
                    className="font-bold px-2 sm:text-end"
                    initial={{ x: 150 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 1 }}
                  >
                    Breathtaking Landscapes.
                  </motion.i>
                </motion.p>
              </div>
            </div>
            <div className="px-16 sm:px-4">
              <motion.div
                className="bg-white p-8 sm:p-2"
                ref={ref}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96], // This is a custom cubic-bezier function for a smooth ease-in effect
                }}
              >
                <EventSlider />
              </motion.div>
              <section className="tours bg-white p-8">
                <h2 className="section_title font-bold tracking-tight text-[2rem] pb-3">
                  Tour Packages
                </h2>
                <h4 className="subtitle text-[#484848] pb-8">
                  Our tour packages are your gateway to an unforgettable
                  Bhutanese adventure
                </h4>

                <div className="flex flex-wrap overflow-hidden w-full justify-between gap-y-8">
                  <div className="flex flex-wrap justify-between overflow-hidden w-full gap-y-12">
                    {topFourPackages.map((card, index) => (
                      <Card
                        key={index}
                        data={card}
                        showMore={true}
                        link={"/packages/details"}
                        isLoading={isLoading}
                      />
                    ))}
                  </div>
                </div>
              </section>

              <section className="destination bg-white p-8">
                <h2 className="section_title font-bold tracking-tight text-[2rem] pb-3">
                  Destinations
                </h2>
                <h4 className="subtitle text-[#484848] pb-8">
                  Explore amazing places in Bhutan to make your trip worth.
                </h4>
                <div className="flex flex-wrap overflow-hidden w-full justify-between gap-y-8">
                  <div className="flex flex-wrap justify-between overflow-hidden w-full gap-y-12">
                    {topFourHomestays.map((card, index) => (
                      <Card
                        link={"/destinations"}
                        key={index}
                        data={card}
                        showMore={true}
                        isLoading={isLoading}
                      />
                    ))}
                  </div>
                </div>
              </section>

              <section className="hotels bg-white p-8">
                <h2 className="section_title font-bold tracking-tight text-[2rem] pb-3">
                  Hotels
                </h2>
                <h4 className="subtitle text-[#484848] pb-8">
                  Bhutanese hotels offer a blend of tradition and Himalayan
                  beauty.
                </h4>

                <div className="flex flex-wrap overflow-hidden w-full justify-between gap-y-8">
                  <div className="flex flex-wrap justify-between overflow-hidden w-full gap-y-12">
                    {topFourHotel.map((card, index) => (
                      <Card key={index} data={card} isLoading={isLoading} />
                    ))}
                  </div>
                </div>
              </section>

              <section className="bg-white p-8">
                <h1 className="font-bold text-[2rem]">Official Partners</h1>

                <div className="flex justify-evenly items-center py-10">
                  <img
                    src={abto}
                    alt=""
                    className="h-40 w-auto hover:scale-[95%] transition-all duration-500 sm:h-20"
                  />
                  <img
                    src={travel}
                    alt=""
                    className="h-40 w-auto hover:scale-[95%] transition-all duration-500 sm:h-20"
                  />
                </div>
              </section>

              <section className="bg-white p-8 flex sm:flex-col">
                <h1 className="text-5xl font-bold w-1/2 sm:w-full px-8 sm:text-2xl sm:px-2 sm:pb-4">
                  Frequently Asked Questions
                </h1>
                <div className="w-1/2 sm:w-full sm:px-2">
                  <Faqs />
                </div>
              </section>

              <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
                <div className="container mx-auto text-center">
                  <h2 className="text-white text-4xl font-semibold mb-4 sm:text-xl">
                    Ready to plan your trip with Druk Rewa Adventures?
                  </h2>
                  <p className="text-white text-sm mb-6 sm:text-xs">
                    Book your adventure now and explore Bhutan
                  </p>
                  <Link className="" to={"mailto:drukrewa@gmail.com"}>
                    <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                      <span className="hover:text-black relative z-[100]">
                        Book Now
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
          <Footer />
        </div>
      )}
    </AnimatePresence>
  );
};

export default App;
