import NavBarTwo from "../Components/NavBarTwo"
import React, { useState } from 'react';
import ThimphuDzong from "../assets/cho_main.png"
import ParoDzong from "../assets/paro.png"
import PunakhaDzong from "../assets/punakha_main.png"
import WangdueDzong from "../assets/wangdue.png"
import BumthangDzong from "../assets/bumthang_main.png"
import LhuntseDzong from "../assets/tse.png"
import Phuntsholing from "../assets/ling_main.png"
import TrongsaDzong from "../assets/trongsabg.png"
import HaaDzong from "../assets/haa.png"

import buddha from "../assets/destinations/thimphu/buddha.jpg"
import chorten from "../assets/destinations/thimphu/chorten.jpg"
import folk from "../assets/destinations/thimphu/folkheritage.jpg"
import tashichho from "../assets/destinations/thimphu/tashichoo.jpg"
import takin from "../assets/destinations/thimphu/MotithangTakin-Preserve_0.jpg"
import tshechu from "../assets/destinations/thimphu/Thimphu Tshechu (festival).jpg"
import jungshina from "../assets/destinations/thimphu/jungshina.png"
import textile from "../assets/destinations/thimphu/textile.png"
import simtokha from "../assets/destinations/thimphu/simtokha.jpg"
import dochula from "../assets/destinations/thimphu/dochula.jpg"
import changangkha from "../assets/destinations/thimphu/changangkha.jpg"
import zilukha from "../assets/destinations/thimphu/nunnery.png"
import cheri from "../assets/destinations/thimphu/cheri.jpg"
import craft from "../assets/destinations/thimphu/craft.png"

import taksthang from "../assets/destinations/paro/taktshang.png"
import museum from "../assets/destinations/paro/museum.jpg"
import rinpung from "../assets/destinations/paro/Rinpung Dzong _ Bhutan.jpg"
import kyichu from "../assets/destinations/paro/kyichu.jpg"
import dumtse from "../assets/destinations/paro/dumtseg.png"

import pungthang from "../assets/destinations/punakha/punadzong.jpg"
import suspension from "../assets/destinations/punakha/Luxury South East Asia Tours & Guided Asia Tour Packages.jpg"
import khamsum from "../assets/destinations/punakha/khasum.jpg"
import chimi from "../assets/destinations/punakha/chimi.jpg"


import wangduedzong from "../assets/destinations/wangdue/wangdueDzong.jpg"
import wangduetshechu from "../assets/destinations/wangdue/wangdueTshechu.jpg"
import gangtey from "../assets/destinations/wangdue/Gangtey monastery.jpg"
import nature from "../assets/destinations/wangdue/phobjikhatrail.jpg"
import khewa from "../assets/destinations/wangdue/khewa.png"
import rinchengang from "../assets/destinations/wangdue/rinchengang.png"
import pelela from "../assets/destinations/wangdue/pelela.png"

import jakar from '../assets/destinations/bumthang/Jakar Dzong in Bumthang.jpg'
import jambay from "../assets/destinations/bumthang/jambay.jpg"
import mebartsho from "../assets/destinations/bumthang/mebartsho.jpg"
import tamshing from "../assets/destinations/bumthang/tamshing.png"
import kurjey from "../assets/destinations/bumthang/kurje.jpg"
import ogyen from "../assets/destinations/bumthang/ogyen.png"
import sheep from "../assets/destinations/bumthang/sheep.png"

import khoma from "../assets/destinations/lhuntse/khoma.jpg"
import takila  from "../assets/destinations/lhuntse/takila.jpg"

import crocodile from "../assets/destinations/phuentsholing/Amo-Chhu-Crocodile-Breeding-Centre.jpg"
import zangdopelri from "../assets/destinations/phuentsholing/zangdopelri.jpg"

import trongsa from "../assets/destinations/trongsa/Trongsa.png"
import yutong from "../assets/destinations/trongsa/yutong.png"

import chelela from "../assets/destinations/haa/chelela.png"
import katsho from  "../assets/destinations/haa/katsho.png"
import nagpo from "../assets/destinations/haa/nagpo.png"


import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { motion } from "framer-motion";
import AnimatedText from "../Components/AnimatedText";
import { useEffect } from "react";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { useMotionValue, useSpring } from "framer-motion";

const AnimatedNumbers = ({ value, className }) => {
  const ref = useRef(null);
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue, { duration: 3000 });
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      motionValue.set(value);
    }
  }, [isInView, value, motionValue]);

  useEffect(() => {
    springValue.on("change", (latest) => {
      if (ref.current && latest.toFixed(0) <= value) {
        ref.current.textContent = latest.toFixed(0);
      }
    });
  }, [springValue, value]);
  return <span ref={ref} className={`${className}`}></span>;
};

const Destinations = () => {
 const sliders = [
   {
     id: 1,
     bgImage: ThimphuDzong,
     title: "Thimphu",
     description:
       "As Bhutan's capital, Thimphu blends tradition and modernity, offering a taste of Bhutanese culture and urban life.",
     elevation: 3488,
     population: 114.6,
     area: 2067,
   },
   {
     id: 2,
     bgImage: ParoDzong,
     title: "Paro",
     description:
       "Known for its lush landscapes and serene monasteries, Paro offers a unique opportunity to witness the annual Tshechu Festival.",
     elevation: 2266,
     population: 31.4,
     area: 1293,
   },
   {
     id: 3,
     bgImage: PunakhaDzong,
     title: "Punakha",
     description:
       "Steeped in history, Punakha is home to the Punakha Dzong and the annual Punakha Domchoe festival, showcasing traditional Bhutanese culture.",
     elevation: 3176,
     population: 24.3,
     area: 1110,
   },
   {
     id: 4,
     bgImage: WangdueDzong,
     title: "Wangdue Phodrang",
     description:
       "Known for its warm hospitality and friendly locals, Wangdue offers a unique blend of traditional Bhutanese architecture and modern comforts.",
     elevation: 3677,
     population: 42.1,
     area: 4308,
   },
   {
     id: 5,
     bgImage: BumthangDzong,
     title: "Bumthang",
     description:
       "Also known as the 'Heartland', Bumthang is home to several famous Buddhist temples and the annual Bumthang Tsechu festival.",
     elevation: 200,
     population: 14.8,
     area: 2717,
   },
   {
     id: 6,
     bgImage: LhuntseDzong,
     title: "Lhuntse",
     description:
       "Known for its stunning natural beauty, it encompasses rugged mountains, pristine forests, and roaring rivers",
     elevation: 1815,
     population: 14.4,
     area: 1944,
   },
   {
     id: 7,
     bgImage: Phuntsholing,
     title: "Phuentsholing",
     description:
       " Located along the Indian border, Phuentsholing offers a mix of cultural experiences. Visitors can explore the local markets, try traditional Bhutanese food, or visit the impressive Chorten stupa.",
     elevation: 293,
     population: 27.6,
     area: 15.6,
   },
   {
     id: 8,
     bgImage: TrongsaDzong,
     title: "Trongsa",
     description:
       "Trongsa District, the geographical center of Bhutan, offers a blend of stunning landscapes, rich cultural heritage, and serene Buddhist sites.",
     elevation: 1892,
     population: 19.9,
     area: 1807,
   },
   {
     id: 9,
     bgImage: HaaDzong,
     title: "Haa",
     description:
       "Haa Dzongkhag, located along the Indian border, offers a mix of cultural experiences. Visitors can explore the local markets, try traditional Bhutanese food exclusive to the district (Hoentey).",
     elevation: 2718,
     population: 13.7,
     area: 1905,
   },
   // Add more sliders as needed...
 ];

 const dropdownOptions = [
   { sliderId: 1, label: "Destinations" },
   { sliderId: 2, label: "Destinations" },
   { sliderId: 3, label: "Destinations" },
   { sliderId: 4, label: "Destinations" },
   { sliderId: 5, label: "Destinations" },
   { sliderId: 6, label: "Destinations" },
   { sliderId: 7, label: "Destinations" },
   { sliderId: 8, label: "Destinations" },
   { sliderId: 9, label: "Destinations" },
   {
     sliderId: 1,
     label: "Buddha Dordenma",
     image: buddha,
     title: "Buddha Dordenma",
     description:
       "The Buddha Dordenma statue in Thimphu, Bhutan, is a 54-meter tall bronze monument dedicated to bringing peace and prosperity to the world.",
   },
   {
     sliderId: 1,
     label: "Memorial Chorten",
     image: chorten,
     title: "Memorial Chorten",
     description:
       "The Memorial Chorten in Thimphu, Bhutan, is a large stupa-shaped building built to commemorate the memory of the 3rd Dragon King Jigme Dorji Wangchuck.",
   },
   {
     sliderId: 1,
     label: "Folk Heritage Musuem of Thimphu",
     image: folk,
     title: "Folk Heritage Musuem of Thimphu",
     description:
       "The Folk Heritage Museum of Thimphu is a cultural institution that preserves and displays traditional Bhutanese artifacts, costumes, and other historical items, reflecting the rich cultural heritage of the nation.",
   },
   {
     sliderId: 1,
     label: "Tashichho Dzong",
     image: tashichho,
     title: "Tashichho Dzong (Thimphu Dzong)",
     description:
       "Tashichho Dzong, located in Thimphu, Bhutan, is a significant Buddhist monastery and fortress that has traditionally been the seat of Bhutan's civil government and summer capital of the country.",
   },
   {
     sliderId: 1,
     label: "Motithang Takin Preserve",
     image: takin,
     title: "Motithang Takin Preserve",
     description:
       "A sanctuary where takins, the national animal of Bhutan, are raised and protected.",
   },
   {
     sliderId: 1,
     label: "Thimphu Tshechu",
     image: tshechu,
     title: "Thimphu Tshechu",
     description:
       "A three-day religious event held annually, where participants perform sacred mask dances to invoke divine blessings.",
   },
   {
     sliderId: 1,
     label: "Jungshina Handmade Paper Factory",
     image: jungshina,
     title: "Jungshina Handmade Paper Factory",
     description:
       "A fascinating site where traditional paper is produced by hand using local materials.",
   },
   {
     sliderId: 1,
     label: "National Textile Museum",
     image: textile,
     title: "National Textile Museum",
     description:
       "A national treasure house dedicated to preserving and promoting Bhutan's rich textile arts.",
   },
   {
     sliderId: 1,
     label: "Simtokha Dzong",
     image: simtokha,
     title: "Sangak Zabdhon Phodrang (Simtokha Dzong)",
     description:
       "The first dzong built in Bhutan and is the oldest dzong to have survived in it's original form, now a Buddhist monastery and institute for Buddhist studies.",
   },
   {
     sliderId: 1,
     label: "Dochula Pass",
     image: dochula,
     title: "Dochula Pass",
     description:
       "A mountain pass between Thimphu and Punakha which is the home to 108 memorial chortens or stupas known as 'Druk Wangyel Chorten'",
   },
   {
     sliderId: 1,
     label: "Changangkha Lhakhang",
     image: changangkha,
     title: "Changangkha Lhakhang",
     description:
       "The temple for parents seeking blessings for their newborns or young children fom the protective deity Genyen Domtsangpa, the birth deity and protector of children born in JDWNRH.",
   },
   {
     sliderId: 1,
     label: "Zilukha Nunnery",
     image: zilukha,
     title: "Thangthong Dewachen Nunnery (Zilukha Nunnery)",
     description:
       "The largest nunnery in Bhutan housing around 60-70 nun. It was built more than a half century ago situated above the Thimphu Tashichho Dzong",
   },
   {
     sliderId: 1,
     label: "Cheri Monastery",
     image: cheri,
     title: "Chagri Dorjeden Monastery (Cheri Monastery)",
     description:
       "A major teaching and retreat center of the Southern Drukpa Lineage of the Kagyu school of Tibetan Buddhism.",
   },
   {
     sliderId: 1,
     label: "Authentic Bhutanese Craft Bazaar",
     image: craft,
     title: "Authentic Bhutanese Craft Bazaar",
     description:
       "A handicraft market that showcases a wide range of authentic Bhutanese handicrafts and products.",
   },
   {
     sliderId: 2,
     label: "Taktshang Goemba",
     image: taksthang,
     title: "Taktshang Goemba / Tiger's Nest Monastery",
     description:
       "A sacred Himalayan Buddhist site located in the cliffside of the upper Paro valley in Bhutan. It's one of the thirteen Tiger's Nest caves in historical Tibet where Guru Padmasambhava practiced and taught Vajrayana Buddhism.",
   },
   {
     sliderId: 2,
     label: "Paro Rinpung Dzong",
     image: rinpung,
     title: "Rinpung Dzong (Paro Dzong)",
     description:
       "A large Buddhist monastery and fortress of the Drukpa Lineage of the Kagyu school in Paro District, Bhutan. It houses the district Monastic Body as well as government administrative offices of Paro Dzongkhag.",
   },
   {
     sliderId: 2,
     label: "National Museum of Bhutan",
     image: museum,
     title: "National Museum of Bhutan",
     description:
       "A treasure trove of Bhutanese cultural heritage. It houses a vast collection of artifacts, paintings, sculptures, and other exhibits that reflect the rich cultural history of Bhutan.",
   },
   {
     sliderId: 2,
     label: "Kyichu Lhakhang",
     image: kyichu,
     title: "Lho Kyerchu (Kyichu Lhakhang)",
     description:
       "One of the oldest temples in the country originally built in the 7th century by the Tibetan Emperor Songtsen Goenpo.",
   },
   {
     sliderId: 2,
     label: "Dumtse Lhakhang",
     image: dumtse,
     title: "Jangtsa Dumtseg Lhakhang",
     description:
       "A temple in the form of chorten (stupa) built by the great bridge-builder Thangtong Gyalpo", 
   },
   {
     sliderId: 3,
     label: "Punakha Dzong",
     image: pungthang,
     title: "Pungthang Dewachen Phodrang (Punakha Dzong)",
     description:
       "The second largest and second oldest dzong in Bhutan, is a majestic structure that served as the capital of Bhutan from 1637 to 1907.",
   },
   {
     sliderId: 3,
     label: "Khamsum Yulley Namgyal Chorten",
     image: khamsum,
     title: "Khamsum Yulley Namgyal Chorten",
     description:
       "A three-storey chorten that dominates the upper Punakha Valley. The chorten houses paintings belonging to Nyingmapa Traditions.",
   },
   {
     sliderId: 3,
     label: "Chimi Lhakhang",
     image: chimi,
     title: "Chimi Lhakhang (Temple of Fertility)",
     description:
       "The Temple of Fertility visited by childless couples for the fertility of their child.",
   },
   {
     sliderId: 3,
     label: "Punakha Suspension Bridge",
     image: suspension,
     title: "Punakha Suspension Bridge",
     description:
       "Connecting Punakha Dzong to the rest of the valley, is one of the longest and most iconic suspension bridges in the country.",
   },
   {
     sliderId: 4,
     label: "Wangdue Dzong",
     image: wangduedzong,
     title: "Wangdue Phodrang Dzong (Wangdue Dzong)",
     description:
       "A dzong located in Bhutan that shares its name with the town of Wangdue Phodrang.",
   },
   {
     sliderId: 4,
     label: "Wangdue Tshechu",
     image: wangduetshechu,
     title: "Wangdue Tshechu",
     description:
       "A three-day festival held in Wangdue Phodrang, Bhutan, during the autumn season. Known for the Raksha Mangchum or the Dance of the Ox.",
   },
   {
     sliderId: 4,
     label: "Gangtey",
     image: gangtey,
     title: "Gangtey",
     description:
       "Gangtey is a village in Bhutan known for its beautifully preserved manor houses and the Gangtey Drukpa Monastery, one of the oldest and most revered monasteries in the country.",
   },
   {
     sliderId: 4,
     label: "Gangtey Nature Trail",
     image: nature,
     title: "Gangtey Nature Trail",
     description:
       "The opportunity to observe the endangered black-necked cranes in their natural habitat.",
   },
   {
     sliderId: 4,
     label: "Khewa Lhakhang",
     image: khewa,
     title: "Khewa Lhakhang",
     description:
       "Oldest temple in the Phobjikha valley showcasing the Past, Present and Future Buddha statues.",
   },
   {
     sliderId: 4,
     label: "Rinchengang Village",
     image: rinchengang,
     title: "Rinchengang Village",
     description:
       "The village of proficiency in the traditional stonework and one of the oldest villages in the country.",
   },
   {
     sliderId: 4,
     label: "Pelela Pass",
     image: pelela,
     title: "Pelela Pass",
     description:
       "A mountain pass connecting the valleys of Paro and Trongsa Districts.",
   },
   {
     sliderId: 5,
     label: "Jakar Dzong",
     image: jakar,
     title: "Jakar Yugyal Dzong (Jakar Dzong)",
     description:
       "Fortress of the Bumthang District in central Bhutan. It is located on a ridge above Jakar town in the Chamkhar valley of Bumthang.",
   },
   {
     sliderId: 5,
     label: "Jambay Lhakhang",
     image: jambay,
     title: "Jambay Lhakhang",
     description:
       "The Temple of Maitreya, is located in Bumthang, Bhutan. It is said to be one of the 108 temples built by Tibetan King Songtsen Gampo in 659 CE on a single day, to pin down an ogress to earth forever.",
   },
   {
     sliderId: 5,
     label: "Tamshing Lhakhang",
     image: tamshing,
     title: "Tamzhing Lhundrup Monastery (Tamshing Lhakhang)",
     description:
       "The Temple of Good Message, a temple built by the prolific teasure discoverer, Tertoen Pema Lingpa.",
   },
   {
     sliderId: 5,
     label: "Kurjey Lhakhang",
     image: kurjey,
     title: "Kurjey Lhakhang",
     description:
       "Named after the 'body' (kur) and 'print' (jey) of Guru Rinpochhoe  which is preserved in a cave inside the oldest of the three buildings that make up the complex.",
   },
   {
     sliderId: 5,
     label: "Ogyen Choling Palace and Museum",
     image: ogyen,
     title: "Ogyen Choling Palace and Museum",
     description:
       "One of the eight sacred locales established by a revered 14th century Buddhist master.",
   },
   {
     sliderId: 5,
     label: "Dechenpelrithang Sheep Farm",
     image: sheep,
     title: "Dechenpelrithang Sheep Farm",
     description:
       "A National Sheep Breeding Centre of the nation for preserving the importance of sheeps to the highlanders.",
   },
   {
     sliderId: 6,
     label: "Khoma Village",
     image: khoma,
     title: "Khoma Village",
     description:
       "Khoma village in Lhuntse, Bhutan, is renowned for its intricate woven cloth made of silk called Kishuthara (brocade dress).",
   },
   {
     sliderId: 6,
     label: "Takila Guru Rinpochhe Statue",
     image: takila,
     title: "Takila Guru Rinpochhe Statue",
     description:
       "This world’s tallest statue of Guru Padmasambhava, of 173 feet height stands tall on the Takila Mountain slope, overlooking the valley of Tangmachu.",
   },
   {
     sliderId: 7,
     label: "Amochhu Crocodile Breeding Centre",
     image: crocodile,
     title: "Amochhu Crocodile Breeding Centre",
     description:
       "Located behind the bus terminal of phuentsholing, which is the only crocodile breeding centre in Bhutan. Home to two types of endangered species of crocodiles: Gharials and Muggers.",
   },
   {
     sliderId: 7,
     label: "Phuentsholing Zangdopelri Park",
     image: zangdopelri,
     title: "Phuentsholing Zangdopelri Park",
     description:
       "A large stupa located in the heart of Phuentsholing, Bhutan. It is one of the most prominent symbols of Buddhism in the country.",
   },
   {
     sliderId: 8,
     label: "Trongsa Dzong",
     image: trongsa,
     title: "Chhoekhor Rabtentse Dzong (Trongsa Dzong)",
     description:
       "The seat of power for the Trongsa Poenlop and the Wangchuck Dynasty",
   },
   {
     sliderId: 9,
     label: "Chelela Pass",
     image: chelela,
     title: "Chelela Pass",
     description:
       "A mountain pass between the valleys of Haa and Paro",
   },
   {
     sliderId: 9,
     label: "Katsho Goenpa",
     image: katsho,
     title: "Katsho Goenpa",
     description:
       "One of the four biggest temples in Haa where a hundred million Mani recitation in held every 13th of twelth month to 20th day of first month for 21 days",
   },
   {
     sliderId: 9,
     label: "Lhakhang Nagpo",
     image: nagpo,
     title: "Lhakhang Nagpo",
     description:
       "Serving as the seat of the guardian deity Da Do Chen, located 15mins away from the Lhakhang Karpo",
   },
   {
     sliderId: 8,
     label: "Yutongla Pass",
     image: yutong,
     title: "Yutongla Pass",
     description:
       "A mountain pass between the valleys of Trongsa and Bumthang",
   },
 ];

 const [currentIndex, setCurrentIndex] = useState(0);
 const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);

 const filteredDropdownOptions = dropdownOptions.filter((option) => {
  return option.sliderId === currentIndex+1;
 })

 const goToNextSlide = () => {
   setCurrentIndex((currentIndex + 1) % sliders.length);
  setSelectedOption(filteredDropdownOptions[0])
 };

 const goToPreviousSlide = () => {
   setCurrentIndex((currentIndex - 1 + sliders.length) % sliders.length);
   setSelectedOption(filteredDropdownOptions[0])
 };

 const handleSelectChange = (event) => {
  const selectedId = event.target.value
  const selectedOption = dropdownOptions.find((option) => option.label=== selectedId)
  setSelectedOption(selectedOption)
 };





 return (
   <div className="sm:overlflow-x-hidden">
     <NavBarTwo />
     <div
       style={{
         backgroundImage: `url("${
           sliders[currentIndex].bgImage || selectedOption.image
         }")`,
       }}
       className="pt-[8.5rem] flex bg-cover bg-center sm:bg-right justify-center items-center sm:flex-col h-screen sm:h-[200vh]"
     >
       <div
         className={`absolute top-[60%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 hover:bg-black/60 text-black cursor-pointer`}
       >
         <BsChevronCompactLeft
           onClick={goToPreviousSlide}
           size={30}
           className="text-white sm:text-black hover:text-white/50"
         />
       </div>
       <div
         className={`absolute top-[60%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 hover:bg-black/60 text-black cursor-pointer`}
       >
         <BsChevronCompactRight
           onClick={goToNextSlide}
           size={30}
           className="text-white sm:text-black hover:text-white/50"
         />
       </div>

       <div className="bg-white h-[70vh] w-[45vw] sm:h-screen sm:w-screen rounded-tl-3xl sm:rounded-none rounded-bl-3xl p-4 flex flex-col gap-16">
         <h1 className="uppercase p-4 text-center font-black text-5xl sm:text-3xl">
           <AnimatedText text={sliders[currentIndex].title} />
         </h1>
         <p className="px-16 font-thin text-xl sm:text-lg sm:text-center">
           <AnimatedText text={sliders[currentIndex].description} />
         </p>
         <div className="flex gap-10 justify-center items-center sm:flex-col">
           <h1 className="flex flex-col">
             <h1 className="flex items-end">
               <AnimatedNumbers
                 value={sliders[currentIndex].elevation}
                 className={"font-bold text-5xl"}
               />
               <p className="font-regular text-xl">m</p>
             </h1>
             <p className="text-2xl font-thin">Elevation</p>
           </h1>
           <h1 className="flex flex-col border-x-2 border-x-black px-10 sm:border-y-2 sm:border-y-black sm:border-x-0 sm:py-4">
             <h1 className="flex items-end">
               <AnimatedNumbers
                 value={sliders[currentIndex].population}
                 className={"font-bold text-5xl"}
               />
               <p className="text-xl">k+</p>
             </h1>
             <p className="text-2xl font-thin">Population</p>
           </h1>
           <h1 className="flex flex-col">
             <h1 className="flex items-end">
               <AnimatedNumbers
                 value={sliders[currentIndex].area}
                 className={"font-bold text-5xl"}
               />
               <p className="text-xl">km <sup>2</sup></p>
             </h1>
             <p className="text-2xl font-thin">Land Area</p>
           </h1>
         </div>
       </div>
       <div
         className="h-[70vh] w-[45vw] border-[10px] sm:h-screen sm:w-screen border-white sm:border-t-[0.5px] sm:border-t-black sm:rounded-none rounded-tr-3xl rounded-br-3xl flex items-end"
         style={{
           backgroundImage: `url("${selectedOption.image}")`,
           backgroundSize: "cover",
         }}
       >
         <div className="w-full linearcolor h-full rounded-br-xl sm:w-screen sm:rounded-none">
           <select
             value={selectedOption.label}
             onChange={handleSelectChange}
             className="py-4 px-12 sm:px-2 sm:w-full rounded-tr-md rounded-br-md rounded-bl-md focus:outline-double focus:outline-[#fff] sm:outline-black focus:ring-2 focus:ring-gray-500 focus:border-transparent uppercase "
           >
             {filteredDropdownOptions.map((option) => (
               <option key={option.label} value={option.label} className="uppercase">
                 {option.label}
               </option>
             ))}
           </select>
           {selectedOption.label !== "Destinations" && (
             <div className="flex flex-col h-[88%] justify-between sm:justify-center">
               {/* <img src={selectedOption.image} alt={selectedOption.title} className="h-full w-auto object-cover" /> */}
               <h1 className="px-16 uppercase text-white font-bold text-3xl py-4 title sm:text-xl sm:text-center">
                 <AnimatedText text={selectedOption.title} className="title" />
               </h1>
               <div className="py-12 px-16 sm:py-2">
                 <div className="py-4 border-l-4 border-l-white px-4 text-white">
                   <AnimatedText text={selectedOption.description} />
                 </div>
               </div>
             </div>
           )}
         </div>
       </div>
     </div>
   </div>
 );
};

export default Destinations;
