import NavBarTwo from "../Components/NavBarTwo"
import { useEffect, useState } from "react";
import { Location } from "../Components/NavBarLogos";




const PackageDetails = () => {

  const [packages, setPackages] = useState();

  const dbName = window.localStorage.getItem("dbName")



  useEffect(() => {
    // Replace this with your actual fetch function
    console.log("UseEffect")
    const fetchPackages = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API}/packages/one`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dbName: dbName,
          }),
        }
      );

      const data = await response.json();
      console.log("data", data.data)
      setPackages(data.data);
      console.log("packages", packages)
    };
    
    fetchPackages();
  }, []);

  return (
    <div className="sm:overflow-x-hidden">
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4">
        <div className="px-8 py-20 bg-white sm:px-4">
          <h1 className="text-4xl font-bold text-center pb-16 uppercase sm:text-2xl">
            Packages Details
          </h1>
          <section className="w-full">
            {packages && (
                <div className="w-full">
                  <h2 className="font-bold text-3xl uppercase py-4 sm:text-center">
                    {packages.name}
                  </h2>
                  <p className="py-2">{packages.location}</p>
                  {packages.days.map((day, dayIndex) => (
                    <div key={dayIndex} className="w-full pb-10">
                      <h3 className="font-semibold text-2xl uppercase py-2">
                        Day {dayIndex + 1}
                      </h3>
                      <div className="w-full flex flex-wrap gap-7 gap-y-4 items-center sm:justify-center">
                        {day.images.map((image, imageIndex) => (
                          <div key={imageIndex} className="relative w-[19rem] h-[19rem] sm:w-[8rem] sm:h-[8rem]">
                            <img
                              src={image}
                              alt={`Day ${dayIndex} ${imageIndex}`}
                              className="w-full h-full object-cover rounded-md transition-opacity duration-500 ease-in-out"
                            />
                            <p className="absolute inset-0 flex items-center justify-center text-center text-white transition-opacity duration-500 ease-in-out opacity-0 hover:opacity-100 hover:bg-black/70 rounded-md font-thin gap-2">
                            <Location/>
                              {day.caption[imageIndex]}
                            </p>
                          </div>
                        ))}
                      </div>
                      <p className="py-4 font-ligth text-lg sm:text-sm">{day.content}</p>
                    </div>
                  ))}
                </div>
                )}
          </section>
        </div>
      </div>
    </div>
  );
  }

export default PackageDetails