import { useState } from "react"
import Logo from "../../assets/drukrewa.png"
import {toast} from "react-toastify"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"

const Login = () => {
    const navigate = useNavigate()

    const [formData, setFormData ] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e) => {
        const {name, value } = e.target;
        setFormData({...formData,  [name]: value})
        console.log("FormData: ", formData)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.email || !formData.password){
            toast.error("Please fill in all fields")
            return;
        }else if (!formData.email){
            toast.error("Please enter your email address")
            return
        }else if (!formData.password){
            toast.error("Please enter your Password")
            return
        }

        if (formData.email && formData.password){
            console.log("send login data", formData.email, formData.password)

            try{
                let response = await axios.post(`${process.env.REACT_APP_API}/user/login`, formData)
                console.log("login response", response)

                if (response.data){
                    console.log("User logged in")
                    window.localStorage.setItem("auth", JSON.stringify(response.data))
                    window.localStorage.setItem("user", "logged in")
                    toast.success("Login Successful")
                    navigate("/")
                }
            }catch(err){
                console.log("catchblock error", err)
                if (err.response.status === 400) toast.error(err.response.data)
            }
        }
    }

  return (
    <div className="buddha2 h-screen w-screen">
      <div className="rounded-md flex flex-col justify-center items-center">
        <Link className="flex justify-center items-center py-10" to={"/"}>
          <img src={Logo} alt="" className="w-24 h-auto" />
        </Link>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-1/3 h-[70vh] pt-20 sm:w-full"
        >
          <div className="flex flex-col px-5 gap-4">
            <input
              type="email"
              name="email"
              className="m-3 border-b border-gray-500 bg-transparent"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              className="m-3 border-b border-gray-500 bg-transparent"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleChange}
            />
            <div className="w-full flex justify-center items-center">
              <div
                className="rounded-full border border-white"
                to={"/user/register"}
              >
                <button
                  className="py-2 px-6 uppercase cta-button"
                  type="submit"
                  disabled={!formData.email || !formData.password}
                >
                  <span
                    className={`hover:text-black relative z-[100] ${
                      !formData.email || !formData.password
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                  >
                    Submit
                  </span>
                </button>
              </div>
            </div>
            <small className="text-white">
              Don't have an account?{" "}
              <Link
                to={"/user/register"}
                className="font-bold text-white text-md underline"
              >
                Register
              </Link>
            </small>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login