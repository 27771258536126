// Destinations of the tours
import thimphu from "../assets/destinations/thimphu/tashichoo.jpg"
import paro from "../assets/destinations/paro/Rinpung Dzong _ Bhutan.jpg"
import punakha from "../assets/destinations/punakha/punadzong.jpg"
import wangude from "../assets/destinations/wangdue/wangdueDzong.jpg"
import trongsa from "../assets/destinations/trongsa/Trongsa.png"
import bumthang from "../assets/destinations/bumthang/Jakar Dzong in Bumthang.jpg"
import lhuntse from '../assets/destinations/lhuntse/takila.jpg'
import phuentsholing from "../assets/destinations/phuentsholing/zangdopelri.jpg"

import dagala from "../assets/dagala.png"
import jomolhari from "../assets/jomolhari.jpg"
import drukpath from "../assets/drukpath.jpg"

import memorial from "../assets/destinations/thimphu/chorten.jpg"
import buddha from "../assets/destinations/thimphu/buddha.jpg"
import takin from "../assets/destinations/thimphu/MotithangTakin-Preserve_0.jpg"
import museum from "../assets/destinations/paro/museum.jpg"

import folk from "../assets/destinations/thimphu/folkheritage.jpg"
import kyichu from "../assets/destinations/paro/kyichu.jpg"
import taktshang from "../assets/destinations/paro/taktshang.png"

import dochula from "../assets/destinations/thimphu/dochula.jpg"
import gangteywangdue from "../assets/destinations/wangdue/Gangtey monastery.jpg"

import changangkha from "../assets/destinations/thimphu/changangkha.jpg"
import kurjey from "../assets/destinations/bumthang/kurje.jpg"
import ogyen from "../assets/destinations/bumthang/ogyen.png"

import dumtse from "../assets/destinations/paro/dumtseg.png"

import paper from "../assets/destinations/thimphu/jungshina.png"
import textile from "../assets/destinations/thimphu/textile.png"
import khamsum from "../assets/destinations/punakha/khasum.jpg"
import katsho from "../assets/destinations/haa/katsho.png"

import kuenphen from "../assets/hotels/thimphu/kuenphen/kuenphen rabten.jpg";
import kuenphen1 from "../assets/hotels/thimphu/kuenphen/kuenphen rabten 2.jpg"
import kuenphen2 from "../assets/hotels/thimphu/kuenphen/kuenphen rabten 3.jpg"
import kuenphen3 from "../assets/hotels/thimphu/kuenphen/kuenphen rabten 4.jpg"


import city from "../assets/hotels/thimphu/city/city hotel.jpg";
import city1 from "../assets/hotels/thimphu/city/city hotel 2.jpg";
import city2 from "../assets/hotels/thimphu/city/city hotel 3.jpg";
import city3 from "../assets/hotels/thimphu/city/city hotel 4.jpg";

import druk from "../assets/hotels/thimphu/druk/drukhotel.png"
import druk1 from "../assets/hotels/thimphu/druk/drukhotel 2.jpg"
import druk2 from "../assets/hotels/thimphu/druk/drukhotel.jpg"
import druk3 from "../assets/hotels/thimphu/druk/drukhotel 1.png"

import lobesa from "../assets/hotels/punakha/lobesa/hotellobesa.jpg"
import lobesa1 from "../assets/hotels/punakha/lobesa/hotellobesa 2.jpg"
import lobesa2 from "../assets/hotels/punakha/lobesa/hotellobesa 1.jpg"
import lobesa3 from "../assets/hotels/punakha/lobesa/hotellobesa 3.jpg"

import dhensa from "../assets/hotels/punakha/dhensa/dhensa.jpg"
import dhensa1 from "../assets/hotels/punakha/dhensa/dhensa2.jpg"
import dhensa2 from "../assets/hotels/punakha/dhensa/dhensa3.jpg"
import dhensa3 from '../assets/hotels/punakha/dhensa/dhensa4.jpg'

import zhingkham from "../assets/hotels/punakha/zhingkhamresort/zhingkhamresort.png"
import zhingkham1 from "../assets/hotels/punakha/zhingkhamresort/zhingkhamresort5.png"
import zhingkham2 from "../assets/hotels/punakha/zhingkhamresort/zhingkhamresort2.png"
import zhingkham3 from "../assets/hotels/punakha/zhingkhamresort/zhingkhamresort3.png"
import zhingkham4 from "../assets/hotels/punakha/zhingkhamresort/zhingkhamresort4.png"

import rkpo from "../assets/hotels/punakha/rkpo/rkpo.png"
import rkpo1 from "../assets/hotels/punakha/rkpo/rkpo 1.png"
import rkpo2 from "../assets/hotels/punakha/rkpo/rkpo2.png"
import rkpo3 from "../assets/hotels/punakha/rkpo/rkpo3.png"
import rkpo4 from "../assets/hotels/punakha/rkpo/rkpo4.png"

import janka from "../assets/hotels/paro/janka/janka.jpg"
import janka1 from "../assets/hotels/paro/janka/janka1.jpg"
import janka2 from "../assets/hotels/paro/janka/janka2.jpg"
import janka3 from "../assets/hotels/paro/janka/janka3.jpg"

import zhiwaling from "../assets/hotels/paro/zhiwa/zhiwa.png"
import zhiwaling1 from "../assets/hotels/paro/zhiwa/zhiwa1.png"
import zhiwaling2 from "../assets/hotels/paro/zhiwa/zhiwa2.jpg"
import zhiwaling3 from "../assets/hotels/paro/zhiwa/zhiwa3.jpg"
import zhiwaling4 from "../assets/hotels/paro/zhiwa/zhiwa4.jpg"
import zhiwaling5 from "../assets/hotels/paro/zhiwa/zhiwa5.jpg"

import naksel from "../assets/hotels/paro/naksel/naksel.png"
import naksel1 from "../assets/hotels/paro/naksel/naksel1.png"
import naksel2 from "../assets/hotels/paro/naksel/naksel2.png"
import naksel3 from "../assets/hotels/paro/naksel/naksel3.png"
import naksel4 from "../assets/hotels/paro/naksel/naksel4.png"

import tenzin from "../assets/hotels/paro/tenzinling/tenzinling.png"
import tenzin1 from "../assets/hotels/paro/tenzinling/tenzinling1.png"
import tenzin2 from "../assets/hotels/paro/tenzinling/tenzinling2.png"
import tenzin3 from "../assets/hotels/paro/tenzinling/tenzinling3.png"
import tenzin4 from "../assets/hotels/paro/tenzinling/tenzinling4.png"

import olathang from "../assets/hotels/paro/olathang/olathang.jpg"
import olathang1 from "../assets/hotels/paro/olathang/olathang1.png"
import olathang2 from "../assets/hotels/paro/olathang/olathang2.png"
import olathang3 from "../assets/hotels/paro/olathang/olathang3.png"
import olathang4 from "../assets/hotels/paro/olathang/olathang4.png"

import gangtey from "../assets/hotels/wangdue/gangtey/gangtey.png"
import gangtey1 from "../assets/hotels/wangdue/gangtey/gangtey1.png"
import gangtey2 from "../assets/hotels/wangdue/gangtey/gangtey2.png"
import gangtey3 from "../assets/hotels/wangdue/gangtey/gangtey3.png"
import gangtey4 from "../assets/hotels/wangdue/gangtey/gangtey4.png"
import gangtey5 from "../assets/hotels/wangdue/gangtey/gangtey5.png"

import dewachen from "../assets/hotels/wangdue/dewachen/dewachen.png"
import dewachen1 from "../assets/hotels/wangdue/dewachen/dewachen1.png"
import dewachen2 from "../assets/hotels/wangdue/dewachen/dewachen2.png"
import dewachen3 from "../assets/hotels/wangdue/dewachen/dewachen3.png"
import dewachen4 from "../assets/hotels/wangdue/dewachen/dewachen4.png"

import mountain from "../assets/hotels/bumthang/mountain/mountain.png"
import mountain1 from "../assets/hotels/bumthang/mountain/mountain1.png"

import amankora from "../assets/hotels/bumthang/amankora/amankora.png"
import amankora1 from "../assets/hotels/bumthang/amankora/amankora1.png"
import amankora2 from "../assets/hotels/bumthang/amankora/amankora2.png"
import amankora3 from "../assets/hotels/bumthang/amankora/amankora3.png"

import jakar from "../assets/hotels/bumthang/jakar/jakar.png"
import jakar1 from "../assets/hotels/bumthang/jakar/jakar1.png"
import jakar2 from "../assets/hotels/bumthang/jakar/jakar2.png"
import jakar3 from "../assets/hotels/bumthang/jakar/jakar3.png"

import ugyen from "../assets/hotels/lhuntse/ugyen/ugyengaki.jpg"

import hoteldruk from "../assets/hotels/phuentsholing/druk/druk.png"
import hoteldruk1 from "../assets/hotels/phuentsholing/druk/druk1.png"
import hoteldruk2 from "../assets/hotels/phuentsholing/druk/druk2.png"
import hoteldruk3 from "../assets/hotels/phuentsholing/druk/druk3.png"
import hoteldruk4 from "../assets/hotels/phuentsholing/druk/druk4.png"

import central from "../assets/hotels/phuentsholing/central/central.png"
import central1 from "../assets/hotels/phuentsholing/central/central1.png"
import central2 from "../assets/hotels/phuentsholing/central/central2.png"
import central3 from "../assets/hotels/phuentsholing/central/central3.png"
import central4 from "../assets/hotels/phuentsholing/central/central4.png"

import park from "../assets/hotels/phuentsholing/park/park.png"
import park1 from "../assets/hotels/phuentsholing/park/park1.png"
import park2 from "../assets/hotels/phuentsholing/park/park2.png"

import palm from "../assets/hotels/phuentsholing/palm/palm.png"
import palm1 from "../assets/hotels/phuentsholing/palm/palm1.png"
import palm2 from "../assets/hotels/phuentsholing/palm/palm2.png"
import palm3 from "../assets/hotels/phuentsholing/palm/palm3.png"
import palm4 from "../assets/hotels/phuentsholing/palm/palm4.png"


async function getAllRatingsFromDatabase() {
  // replace this with your actual function for fetching all ratings from the database
  // this is just a placeholder
  const response = await fetch(`${process.env.REACT_APP_API}/reviews`);
  const data = await response.json();
  console.log("data", data.data)
  return data.data;
}

const updateReviews= async() => {
  const allRatings = await getAllRatingsFromDatabase();
  const updatedData = data.map((item) => {
    const matchingRating = allRatings.find(
      (rating) => rating.name === item.dbName
    );

    console.log("matching items", matchingRating)
    return {
      ...item,
      reviews: matchingRating ? matchingRating.rating : item.reviews,
    };
  });

  console.log("updateData", updatedData)
  return updatedData 
}

const updatedData = updateReviews()

let data = [
  {
    dbName: "thimphu",
    images: [thimphu],
    name: "Thimphu",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "paro",
    images: [paro],
    name: "Paro",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "punakha",
    images: [punakha],
    name: "Punkha",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "wangdue",
    images: [wangude],
    name: "Wangdue Phodrang",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "trongsa",
    images: [trongsa],
    name: "Trongsa",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "bumthang",
    images: [bumthang],
    name: "Bumthang",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "lhuntse",
    images: [lhuntse],
    name: "Lhuntse",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "phuentsholing",
    images: [phuentsholing],
    name: "Phuentsholing, Chhukha",
    location: "Thimphu, Bhutan",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "destinations",
  },
  {
    dbName: "lemeridenthimphu",
    images: [
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/07/9b/45/4a/le-meridien-thimphu.jpg?w=700&h=-1&s=1",
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/468485351.jpg?k=711f658ed256c1785a6edc778b7f0910e049380f621b5fc93647f2395a2be5ee&o=&hp=1",
    ],
    name: "Le Méridien",
    location: "Thimphu",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "hotel",
  },
  {
    dbName: "lemeridenparo",
    images: [
      "https://gos3.ibcdn.com/bddc81c49ef611e7ba32025f77df004f.jpg",
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/52/c9/45/exterior.jpg?w=700&h=-1&s=1",
    ],
    name: "Riverpoint Le Méridien",
    location: "Paro",
    date: "Jan 8-12",
    price: "Nu.12,000",
    reviews: 4.81,
    type: "hotel",
  },
  {
    dbName: "dhensa",
    images: [dhensa, dhensa1, dhensa2, dhensa3],
    name: "Dhensa",
    location: "Punakha",
    date: "Feb 8-12",
    price: "Nu.25,000",
    reviews: 3.9,
    type: "hotel",
  },
  {
    dbName: "kuenphen",
    images: [kuenphen, kuenphen1, kuenphen2, kuenphen3],
    name: "Kuenphen Rabten Resort",
    location: "Thimphu",
    date: "Jan 12-16",
    price: "Nu.18,000",
    reviews: 4.5,
    type: "hotel",
  },
  {
    dbName: "cityhotel",
    images: [city, city1, city2, city3],
    name: "City Hotel",
    location: "Thimphu",
    date: "Jan 10-17",
    price: "Nu.8,000",
    reviews: 4.57,
    type: "hotel",
  },
  {
    dbName: "drukhotel",
    images: [druk, druk1, druk2, druk3],
    name: "Druk Hotel",
    location: "Thimphu",
    date: "September 20-24",
    price: "Nu.8,000",
    reviews: 4.7,
    type: "hotel",
  },
  {
    dbName: "lobesa",
    images: [lobesa, lobesa1, lobesa2, lobesa3],
    name: "Hotel Lobesa",
    location: "Punakha",
    date: "Jan 18-22",
    price: "Nu.9,000",
    reviews: 4.3,
    type: "hotel",
  },
  {
    dbName: "zhingkham",
    images: [zhingkham1, zhingkham, zhingkham2, zhingkham3, zhingkham4],
    name: "Zhingkham Resort",
    location: "Thimphu",
    date: "April 8-12",
    price: "Nu.24,000",
    reviews: 4.18,
    type: "hotel",
  },
  {
    dbName: "rkpo",
    images: [rkpo, rkpo1, rkpo2, rkpo3, rkpo4],
    name: "RKPO Green Resort",
    location: "Punakha",
    date: "Feb 2-7",
    price: "Nu.15,000",
    reviews: 4.45,
    type: "hotel",
  },
  {
    dbName: "janka",
    images: [janka, janka1, janka2, janka3],
    name: "Janka Resort",
    location: "Paro",
    date: "Feb 6-8",
    price: "Nu.12,000",
    reviews: 4.1,
    type: "hotel",
  },
  {
    dbName: "zhiwaling",
    images: [
      zhiwaling,
      zhiwaling1,
      zhiwaling2,
      zhiwaling3,
      zhiwaling4,
      zhiwaling5,
    ],
    name: "Zhiwaling Heritage",
    location: "Paro",
    date: "March 2, 12",
    price: "Nu.12,005",
    reviews: 4.01,
    type: "hotel",
  },
  {
    dbName: "naksel",
    images: [naksel, naksel1, naksel2, naksel3, naksel4],
    name: "Naksel Boutique Hotel & Spa",
    location: "Paro",
    date: "May 23-29",
    price: "Nu.15,000",
    reviews: 4.21,
    type: "hotel",
  },
  {
    dbName: "tenzinling",
    images: [tenzin, tenzin1, tenzin2, tenzin3, tenzin4],
    name: "Tenzinling Luxury Villa",
    location: "Punakha",
    date: "Feb 2-7",
    price: "Nu.1200",
    reviews: 4.45,
    type: "hotel",
  },
  {
    dbName: "olathang",
    images: [olathang, olathang1, olathang2, olathang3, olathang4],
    name: "Olathang Hotel",
    location: "Paro",
    date: "May 21-24",
    price: "Nu.1000",
    reviews: 4.33,
    type: "hotel",
  },
  {
    dbName: "gangtey",
    images: [gangtey, gangtey1, gangtey2, gangtey3, gangtey4, gangtey5],
    name: "Gangtey Lodge",
    location: "Wangdue Phodrang",
    date: "May 12-24",
    price: "Nu.25,000",
    reviews: 4.27,
    type: "hotel",
  },
  {
    dbName: "dewachen",
    images: [dewachen, dewachen1, dewachen2, dewachen3, dewachen4],
    name: "Dewachen Hotel & Spa",
    location: "Wangdue Phodrang",
    date: "June 12-20",
    price: "Nu.2000",
    reviews: 4.05,
    type: "hotel",
  },
  {
    dbName: "ugyen",
    images: [ugyen],
    name: "Ugyen Gakyid Pelzom Guest House",
    location: "Lhuntse",
    date: "August 12-24",
    price: "Nu.9500",
    reviews: 3.6,
    type: "hotel",
  },
  {
    dbName: "mountain",
    images: [mountain, mountain1],
    name: "Mountain Resort",
    location: "Bumthang",
    date: "August 12-24",
    price: "Nu.4000",
    reviews: 4.05,
    type: "hotel",
  },
  {
    dbName: "amankora",
    images: [amankora, amankora1, amankora2, amankora3],
    name: "Amankora Bumthang Lodge",
    location: "Bumthang",
    date: "September 10-20",
    price: "Nu.1800",
    reviews: 4.01,
    type: "hotel",
  },
  {
    dbName: "jakar",
    images: [jakar, jakar1, jakar2, jakar3],
    name: "Jakar Village Lodge",
    location: "Bumthang",
    date: "August 12-24",
    price: "Nu.1500",
    reviews: 3.85,
    type: "hotel",
  },
  {
    dbName: "hoteldruk",
    images: [hoteldruk, hoteldruk1, hoteldruk2, hoteldruk3, hoteldruk4],
    name: "Hotel Druk",
    location: "Phuentsholing",
    date: "April 12-24",
    price: "Nu.1800",
    reviews: 4.09,
    type: "hotel",
  },
  {
    dbName: "central",
    images: [central, central1, central2, central3, central4],
    name: "Central Hotel",
    location: "Phuentsholing",
    date: "April 1-21",
    price: "Nu.30,000",
    reviews: 4.37,
    type: "hotel",
  },
  {
    dbName: "park",
    images: [park, park1, park2],
    name: "Park Hotel",
    location: "Phuentsholing",
    date: "April 1-21",
    price: "Nu.30,000",
    reviews: 4.27,
    type: "hotel",
  },
  {
    dbName: "palm",
    images: [palm, palm1, palm2, palm3, palm4],
    name: "Hotel Palm",
    location: "Phuentsholing",
    date: "April 1-21",
    price: "Nu.30,000",
    reviews: 4.07,
    type: "hotel",
  },
  {
    dbName: "4days",
    images: [memorial, buddha, takin, museum],
    name: "4 Days Tours",
    location: "Paro, Thimphu",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.95,
    type: "packages",
  },
  {
    dbName: "8days",
    images: [dochula, punakha, gangteywangdue, paro],
    name: "8 Days Tour",
    location: "Paro, Thimphu, Punakha, Wangdue",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
      day7: {
        images: [],
        caption: [],
        content: "",
      },
      day8: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.88,
    type: "packages",
  },
  {
    dbName: "5days",
    images: [folk, thimphu, kyichu, taktshang],
    name: "5 Days Tour",
    location: "Paro, Thimphu",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "packages",
  },
  {
    dbName: "10days",
    images: [changangkha, trongsa, kurjey, ogyen],
    name: "10 Days Tour",
    location: "Paro, Thimphu, Punakha, Trongsa, Bumthang, Wangdue",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
      day7: {
        images: [],
        caption: [],
        content: "",
      },
      day8: {
        images: [],
        caption: [],
        content: "",
      },
      day9: {
        images: [],
        caption: [],
        content: "",
      },
      day10: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "packages",
  },
  {
    dbName: "7days",
    images: [paper, textile, khamsum, katsho],
    name: "7 Days Tour",
    location: "Paro, Thimphu, Punakha, Haa",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
      day7: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "packages",
  },
  {
    dbName: "6days",
    images: [takin, dochula, punakha, dumtse],
    name: "6 Days Tour",
    location: "Phuentsholing, Paro, Thimphu, Punakha",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "packages",
  },
  {
    dbName: "dagala",
    images: [dagala],
    name: "Dagala Thousand Lakes Trek (9 Days)",
    location: "Paro, Thimphu",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
      day7: {
        images: [],
        caption: [],
        content: "",
      },
      day8: {
        images: [],
        caption: [],
        content: "",
      },
      day9: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "trekking packages",
  },
  {
    dbName: "drukpath",
    images: [drukpath],
    name: "Druk Path Trek (10 Days)",
    location: "Paro, Thimphu",
    day: {
      day1: {
        images: [],
        caption: [],
        content: "",
      },
      day2: {
        images: [],
        caption: [],
        content: "",
      },
      day3: {
        images: [],
        caption: [],
        content: "",
      },
      day4: {
        images: [],
        caption: [],
        content: "",
      },
      day5: {
        images: [],
        caption: [],
        content: "",
      },
      day6: {
        images: [],
        caption: [],
        content: "",
      },
      day7: {
        images: [],
        caption: [],
        content: "",
      },
      day8: {
        images: [],
        caption: [],
        content: "",
      },
      day9: {
        images: [],
        caption: [],
        content: "",
      },
      day10: {
        images: [],
        caption: [],
        content: "",
      },
    },
    reviews: 4.99,
    type: "trekking packages",
  },
  {
    dbName: "jomolhari",
    images: [jomolhari],
    name: "Jomolhari Trek",
    location: "Paro, Thimphu",
    date: "November 6-10",
    price: "Nu.55,000",
    reviews: 4.99,
    type: "trekking packages",
  },
];


export default updatedData;
