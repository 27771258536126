import NavBarTwo from './../Components/NavBarTwo';
import Bhutanflag from "../assets/bhutan flag.png"
import PunkhaDzong from "../assets/punakhadzong.png"
import Taksthang from "../assets/taktshang.png"
import ThimphuDzong from "../assets/thimphudzong.png"
import Buddhapoint from "../assets/buddhapoint.png"
import GangteyGoenpa from "../assets/ganteygoenpa.png"
import Trongsadzong from "../assets/Trongsa.png"
import Narrow from "../assets/narrow.png"
import {motion} from "framer-motion"
import Footer from '../Components/Footer';
import { useEffect, useState } from 'react';
import { useMotionValue, useSpring, useInView } from 'framer-motion';
import { useRef } from 'react';
import {Link} from 'react-router-dom'
import GNH from '../Components/GNH';

const AnimatedNumbers = ({ value }) => {
  const ref = useRef(null);
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue, { duration: 3000 });
  const isInView = useInView(ref, { once: true });
  
  useEffect(() => {
    if (isInView) {
      motionValue.set(value);
    }
  }, [isInView, value, motionValue]);

  useEffect(() => {
    springValue.on("change", (latest) => {
      if (ref.current && latest.toFixed(0) <= value) {
        ref.current.textContent = latest.toFixed(0);
      }
    });
  }, [springValue, value]);
  return <span ref={ref}></span>;
};

const Bhutan = () => {
  const [imageLoad, setImageLoad] = useState(true);

  return (
    <div className='sm:overflow-x-hidden'>
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4 ">
        <div className="px-32 py-20 bg-white sm:px-4">
          <div className="flex flex-col gap-4">
            <div className="w-full flex gap-5">
              <motion.div
                className="font-bold text-7xl sm:text-xl uppercase text-center border-[0.5rem] rounded-md border-[#2C2C2C] w-2/3 justify-center items-center flex"
                initial={{ x: -50, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                bhutan
              </motion.div>
              <motion.img
                loading="lazy"
                onLoad={() => setImageLoad(false)}
                src={Bhutanflag}
                alt="bhutan's national flag"
                initial={{ x: 50, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                className={imageLoad ? "shimmer" : "sm:h-20"}
              />
            </div>
            <div className="w-full flex gap-5">
              <motion.img
                loading="lazy"
                onLoad={() => setImageLoad(false)}
                src={PunkhaDzong}
                alt="punakha dzong located in the punakha district, the former capital and the winter residence of the je khenpo, the religious leader of the bhutan"
                initial={{ x: -50, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                className={imageLoad ? "shimmer" : "sm:hidden"}
              />
              <motion.div
                className="font-thin bg-[#2C2C2C] text-xl sm:text-sm sm:w-full text-center text-white w-2/3 justify-center items-center flex p-8 rounded-md"
                initial={{ x: 50, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                In the heart of the mighty Himalayas lies Bhutan, a place of
                unspoiled beauty and ancient culture. Visiting this enchanting
                kingdom isn't just a vacation; it's a journey through centuries
                of tradition and history.
              </motion.div>
            </div>
          </div>
          <div className="flex flex-col pt-20">
            <h1 className="font-bold text-5xl sm:text-2xl">A Sanctuary for the Soul</h1>
            <div className="flex justify-between items-center text-start font-thin gap-72 sm:gap-2 sm:text-sm text-2xl pt-8">
              <p>
                Bhutan, the land of the Thunder Dragon, cloaks you in its
                alluring tranquility. Partake in the richness of life against
                majestic mountains, unspoilt landscapes, and verdant valleys.
              </p>
              <p>
                Experience the vibes of joy, peace, and contentment that reign
                supreme here. This enchanting serenity isn’t just a backdrop,
                it’s a lifestyle that permeates the heart of this heavenly
                kingdom
              </p>
            </div>
          </div>
          <div className="h-screen pt-20">
            <h1 className="font-bold text-5xl sm:text-2xl">Gross National Happiness</h1>
            <div className="flex justify-between items-center text-start font-thin gap-72 sm:gap-2 sm:text-sm text-2xl pt-8">
              <p className="w-1/3 sm:w-[100%] sm:text-center">
                Explore the unique approach to measuring progress and
                well-being.
              </p>
            </div>
            <GNH />
          </div>
          <div className="flex flex-col pt-60 sm:pt-0 sm:-mt-40">
            <h1 className="font-bold text-5xl sm:text-2xl">Witness Our Magic</h1>
            <div className="flex flex-col pt-8 gap-4">
              <motion.div className="flex justify-between sm:flex-col sm:gap-4">
                <img
                  src={GangteyGoenpa}
                  alt="located in the wangdue district, tourist attraction"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
                <img
                  src={Narrow}
                  alt="a typical valley in bhutan as the nation is surrounded by mountains, narrow valleys are very much prominent"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
                <img
                  src={ThimphuDzong}
                  alt="the administrative office of the  nation, the place for the annual thimphu tshechu"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
              </motion.div>
              <div className="flex justify-between sm:flex-col sm:gap-4">
                <img
                  src={Trongsadzong}
                  alt="the fortress of the mighty tronsga poenlops which the royal wangchuck dynasty of the royal bhutan started"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
                <img
                  src={Buddhapoint}
                  alt="a tourist attraction, a ginormous statue of Lord Buddha the religious god of the nation's primary religion, Buddhism"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
                <img
                  src={Taksthang}
                  alt="a tourist attraction, a monastery known for its location near on the dangerous cliff and it famous tiger's nest"
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={
                    imageLoad
                      ? "shimmer"
                      : "hover:scale-[90%] transition-transform duration-500 sm:w-full"
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-20">
            <h1 className="font-bold text-5xl sm:text-2xl sm:text-center">
              Hold your Breath for the Spectacular
            </h1>
            <div className="flex justify-between items-center text-start font-thin gap-72 sm:text-lg text-2xl pt-8">
              <p className="w-1/3 sm:w-full">
                Engulfed in tradition and spirituality, Bhutan's vibrant
                cultural tapestry is woven with ancient rituals, flamboyant
                festivals, and venerable monasteries.
              </p>
            </div>
            <div className="grid grid-cols-4 pt-8 sm:flex sm:justify-center sm:items-center sm:px-2">
              <div className="">
                <div className="font-bold text-5xl sm:text-3xl">
                  <AnimatedNumbers value={108} />
                </div>
                <p className="font-thin text-xl sm:text-lg">Sacred Dzongs</p>
              </div>
              <div className="">
                <div className="font-bold text-5xl sm:text-3xl">
                  <AnimatedNumbers value={365} />
                </div>
                <p className="font-thin text-xl sm:text-lg">Festive Days</p>
              </div>
              <div className="">
                <div className="font-bold text-5xl sm:text-3xl">
                  <AnimatedNumbers value={24} />/<AnimatedNumbers value={7} />
                </div>
                <p className="font-thin text-xl sm:text-lg">Everlasting Happiness</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
          <div className="container mx-auto text-center">
            <h2 className="text-white text-4xl font-semibold mb-4 sm:text-xl">
              So start your trip with us right here right now
            </h2>
            <p className="text-white text-sm mb-6 sm:text-xs">
              Book your adventure now and explore Bhutan
            </p>
            <Link className="" to={"mailto:drukrewa@gmail.com"}>
              <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                <span className="hover:text-black relative z-[100]">
                  Book Now
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Bhutan