export const Users = () => {
      return (
        <svg
          width="45"
          height="50"
          viewBox="0 0 56 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="55.8849" height="60.872" rx="27.9425" fill="black" />
          <path
            d="M49.6547 51.794C46.0483 45.003 40.2677 40.3663 33.5703 38.6947C36.7528 37.2744 39.3838 34.6946 41.0138 31.3959C42.6438 28.0972 43.1717 24.2843 42.5072 20.6087C41.8428 16.9332 40.0272 13.6229 37.3708 11.2436C34.7143 8.86426 31.3817 7.56348 27.9425 7.56348C24.5032 7.56348 21.1707 8.86426 18.5142 11.2436C15.8578 13.6229 14.0422 16.9332 13.3777 20.6087C12.7133 24.2843 13.2412 28.0972 14.8712 31.3959C16.5012 34.6946 19.1322 37.2744 22.3147 38.6947C15.626 40.3591 9.83666 45.003 6.23033 51.794C6.12594 52.0114 6.10269 52.2643 6.16544 52.4999C6.22819 52.7355 6.37209 52.9356 6.56707 53.0583C6.76206 53.1811 6.99306 53.2171 7.21181 53.1587C7.43056 53.1003 7.62015 52.9522 7.74097 52.7451C12.0066 44.7058 19.5641 39.905 27.9425 39.905C36.3209 39.905 43.8784 44.7058 48.144 52.7451C48.2206 52.8896 48.3307 53.0096 48.4632 53.093C48.5958 53.1765 48.7462 53.2205 48.8993 53.2207C49.0528 53.2212 49.2036 53.1769 49.3359 53.0923C49.5361 52.966 49.6821 52.7584 49.7418 52.515C49.8016 52.2716 49.7702 52.0123 49.6547 51.794ZM14.8445 23.7358C14.8445 20.9141 15.6127 18.1558 17.0519 15.8096C18.4911 13.4634 20.5367 11.6348 22.9301 10.555C25.3234 9.47516 27.957 9.19263 30.4978 9.74312C33.0386 10.2936 35.3724 11.6524 37.2042 13.6476C39.036 15.6429 40.2835 18.185 40.7888 20.9525C41.2942 23.72 41.0348 26.5886 40.0435 29.1955C39.0521 31.8025 37.3733 34.0306 35.2194 35.5983C33.0654 37.166 30.533 38.0027 27.9425 38.0027C24.4699 37.9983 21.1407 36.4938 18.6853 33.8192C16.2298 31.1446 14.8485 27.5183 14.8445 23.7358Z"
            fill="white"
          />
        </svg>
      );
}