import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Bhutan from './pages/Bhutan';
import Drukrewa from './pages/DrukRewa';
import Contactus from './pages/Contactus';
import Hotels from './pages/Hotels';
import Destinations from './pages/Destinations';
import Visa from './pages/Visa';
import Packages from './pages/Packages';
import NotFound from './pages/NotFound';
import Rooms from './pages/Room';
import PackageDetails from './pages/PackageDetails';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import AdminLogin from "./pages/admin/AdminLogin"
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Dashboard from './pages/admin/Dashboard';
import GNH from './Components/GNH';
import AdminTourUpdate from './pages/admin/AdminTourUpdate';
import Sustainability from './pages/Sustainability';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer position="top-center" style={{ zIndex: 10000 }} />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/bhutan" element={<Bhutan />} />
        <Route path="/drukrewa" element={<Drukrewa />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/destinations" element={<Destinations />} />
        <Route path="/visa" element={<Visa />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/room" element={<Rooms />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/packages/details" element={<PackageDetails />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/register" element={<Register />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/rating" element={<GNH />} />
        <Route path="/admin/tour/update" element={<AdminTourUpdate />} />
        <Route path='/sustainability' element={<Sustainability/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
