import logo from "../assets/drukrewa.png"
import { CiFacebook, CiInstagram } from "react-icons/ci";
import { RiGoogleLine, RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="px-16 py-8 grid grid-cols-4 sm:px-4">
      <div className="flex flex-col gap-8">
        <img src={logo} alt="" className="w-24 h-auto sm:w-12" />
        <div className="flex gap-2">
          <Link to={"mailto:drukrewa@gmail.com"}>
            <RiGoogleLine className="h-8 w-auto sm:h-4" />
          </Link>
          <Link
            to={
              "https://www.facebook.com/people/Druk-Rewa-Adventures/100063626464161/"
            }
          >
            <CiFacebook className="h-8 w-auto sm:h-4" />
          </Link>
          <Link to={"https://www.instagram.com/drukrewa/?hl=en"}>
            <CiInstagram className="h-8 w-auto sm:h-4" />
          </Link>
        </div>
        <div className="sm:text-xs">
          <p>© {new Date().getFullYear()} Druk Rewa Adventures</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <h1 className="font-bold text-2xl sm:text-lg">Services</h1>
        <div className="flex flex-col gap-4 text-lg sm:text-sm">
          <Link to={"/packages"} className="hover:underline">
            Tour Packages
          </Link>
          <Link to={"/destinations"} className="hover:underline">
            Destinations
          </Link>
          <Link to={"/hotels"} className="hover:underline">
            Hotels
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <h1 className="font-bold text-2xl sm:text-lg">Quick Links</h1>
        <div className="flex flex-col gap-4 text-lg sm:text-sm">
          <Link to={"/"} className="hover:underline">
            Home
          </Link>
          <Link to={"/bhutan"} className="hover:underline">
            Bhutan
          </Link>
          <Link to={"/drukrewa"} className="hover:underline">
            Druk Rewa
          </Link>
          <Link to={"/contactus"} className="hover:underline">
            Contact Us
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <h1 className="font-bold text-2xl sm:text-lg">Address</h1>
        <div className="flex flex-col gap-4 text-lg sm:text-sm">
          <p>Flat No.301, Building No 47A</p>
          <p>Norzin Lam, Thimphu</p>
          <a href="tel:+97517760015">Mobile: 17760015</a>
          <a href="mailto:drukrewa@gmail.com">Email: drukrewa@gmail.com</a>
        </div>
      </div>
    </div>
  );
}

export default Footer