import React, { useState } from "react";
import axios from "axios";
import {toast} from "react-toastify"

const Calendar = () => {
  const [event, setEvent] = useState({
    imageLink: "",
    startDate: "",
    endDate: "",
    title: "",
    location: "",
  });

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formdata", event)
    try {
      const data = await axios.post(`${process.env.REACT_APP_API}/events`, event);

      console.log("Calendar data", data)
      if (data.data.message === "Event created successfully") {
        toast.success("calendar added");
        setEvent({
          imageLink: "",
          startDate: "",
          endDate: "",
          title: "",
          location: "",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col pt-12 w-full px-20">
      <h1 className="text-center text-4xl font-bold uppercase pb-8">
        Add Calendar
      </h1>
      <div className="flex items-center gap-10 w-2/3 pb-8">
        <div className="flex flex-col w-1/3">
          <label htmlFor="imagelink" className="font-medium text-lg py-2">
            Image Link
          </label>
          <input
            type="text"
            name="imageLink"
            value={event.imageLink}
            onChange={handleChange}
            placeholder="Image Link"
            className="border-b border-gray-500"
          />
        </div>
        <div className="flex flex-col w-1/3">
          <label htmlFor="Title" className="font-medium text-lg py-2">
            Title
          </label>
          <input
            type="text"
            name="title"
            value={event.title}
            onChange={handleChange}
            placeholder="Title"
            className="border-b border-gray-500"
          />
        </div>
      </div>
      <div className="flex w-2/3 gap-10  items-center pb-8">
        <div className="flex flex-col w-1/3">
          <label htmlFor="Start Date" className="font-medium text-lg py-2">
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            value={event.startDate}
            onChange={handleChange}
            placeholder="Start Date"
            className="border-b border-gray-500"
          />
        </div>

        <div className="flex flex-col w-1/3">
          <label htmlFor="End Date" className="font-medium text-lg py-2">
            End Date
          </label>
          <input
            type="date"
            name="endDate"
            value={event.endDate}
            onChange={handleChange}
            placeholder="End Date"
            className="border-b border-gray-500"
          />
        </div>
      </div>

      <div className="flex flex-col w-2/3">
        <label htmlFor="Location" className="font-medium text-lg py-2">
          Location
        </label>
        <input
          type="text"
          name="location"
          value={event.location}
          onChange={handleChange}
          placeholder="Location"
          className="border-b border-gray-500 w-1/3"
        />
      </div>
      <div className="flex justify-start items-center py-10">
        <button
          className="cta-button bg-black text-white border px-6 py-2 rounded-full uppercase text-lg font-sans hover:bg-white hover:text-black hover:border-black hover:transition-all hover:duration-1000"
          type="submit"
        >
          <span className="hover:text-black relative z-[100]">
            Submit
          </span>
        </button>
      </div>
    </form>
  );
};

export default Calendar;
