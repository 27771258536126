import { useState, useEffect, useRef } from "react"
import Logo from "../../assets/drukrewa.png"
import { Link } from "react-router-dom"
import General from "../../Components/Admin/General"
import Tours from "../../Components/Admin/Tours"
import Testimonials from "../../Components/Admin/Testimonials"
import Calendar from "../../Components/Admin/Calendar"

const Dashboard = () => {

const [selectedNav, setSelectedNav] = useState("general")
const indicatorRef = useRef(null)

  const renderNav = () => {
    switch(selectedNav) {
        case "general": 
            return <General/>;
        case "calendar": 
            return <Calendar/>;
        case "packages":
            return <Tours/>
        case "testimonials": 
            return <Testimonials/>
        default: 
        return null;
    }   
  }
  
  const handleNavClick = (navItem) => {
    setSelectedNav(navItem)
  }

  useEffect(() => {
    const indicator = indicatorRef.current;
    const selectedButton = document.querySelector( `button[aria-label="${selectedNav}"]`)
    
    if (indicator && selectedButton){
        const buttonOffset = selectedButton.offsetTop;
        indicator.style.transform = `translateY(${buttonOffset}px)`
    }
  }, [selectedNav])

  const isAdmin = window.localStorage.getItem("admin")


  return isAdmin === "admin in" ? (
    <div className="flex overflow-hidden relative">
      <div className="w-1/5 bg-white h-screen rounded-tr-3xl rounded-br-3xl right-box-shadow z-[200]">
        <div className="flex flex-col justify-center items-center h-1/3 gap-2">
          <img
            src={Logo}
            alt="drukrewaadventures logo"
            className="h-20 w-auto object-cover"
          />
          <h1 className="font-bold">Admin</h1>
        </div>
        <div className="flex flex-col">
          <button
            onClick={() => handleNavClick("general")}
            className="w-full py-2 px-4 rounded-md mb-2"
            aria-label="general"
          >
            <div className="w-full flex justify-center items-center">
              <p
                className={`${
                  selectedNav === "general" ? "text-white" : "text-black"
                } z-[9999] relative`}
              >
                General
              </p>
            </div>
          </button>
          <button
            onClick={() => handleNavClick("calendar")}
            className="w-full py-2 px-4 rounded-md mb-2"
            aria-label="calendar"
          >
            <div className="w-full flex justify-center items-center">
              <p
                className={`${
                  selectedNav === "calendar" ? "text-white" : "text-black"
                } z-[9999] relative`}
              >
                Calendar
              </p>
            </div>
          </button>
          <button
            onClick={() => handleNavClick("packages")}
            className="w-full py-2 px-4 rounded-md mb-2"
            aria-label="packages"
          >
            <div className="w-full flex justify-center items-center">
              <p
                className={`${
                  selectedNav === "packages" ? "text-white" : "text-black"
                } z-[9999] relative`}
              >
                Packages
              </p>
            </div>
          </button>
        </div>
        <div
          ref={indicatorRef}
          className="indicator h-10 bg-black absolute w-full top-0 left-[50px] rounded-xl transition-transform duration-300 ease-in-out"
          style={{
            width: "14%",
          }}
        />
      </div>
      <div className="w-4/5 inset-0 h-full">
        {renderNav()}
      </div>
    </div>
  ) : (
    <div className="buddha2 w-screen h-screen flex flex-col justify-center items-center gap-12">
      <img src={Logo} alt="drukrewalogo" className="w-32 h-auto" />
      <h1 className="font-bold text-3xl text-white uppercase">
        You are not allowed to access this page
      </h1>
      <div className="flex justify-center items-center gap-4">
        <h1 className="font-bold text-white text-xl">
          Log In as Admin to Access this page
        </h1>
        <Link className="rounded-full border border-white" to={"/admin/login"}>
          <button className="py-2 px-6 uppercase cta-button">
            <span className={`hover:text-black relative z-[100]`}>Log In</span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Dashboard