import NavBarTwo from "../Components/NavBarTwo"
import Footer from "../Components/Footer"
import F1 from "../assets/f1.png"
import F2 from "../assets/f2.png"
import F3 from "../assets/f3.png"
import F4 from "../assets/f4.png"
import F5 from "../assets/f5.png"
import F6 from "../assets/f6.png"
import F7 from "../assets/f7.png"
import {motion} from "framer-motion"
import Chorten from "../assets/chorten.png"
import {Link} from 'react-router-dom'
import { useState } from "react"


const DrukRewa = () => {
  const [imageLoad, setImageLoad] = useState(true)
  return (
    <div className="sm:overflow-x-hidden">
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4">
        <div className="px-20 py-12 bg-white sm:px-2">
          <div className="flex w-full sm:flex-col sm:gap-10 sm:justify-center sm:items-center">
            <div className="w-1/2 sm:w-full">
              <motion.button
                className="uppercase p-2 border border-black rounded-full font-semibold text-xl"
                whileHover={{ scale: 1.1 }}
                initial={{ x: -50, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Druk Rewa Adventures
              </motion.button>
              <motion.p
                className="uppercase font-bold text-7xl pr-20 leading-normal sm:text-4xl sm:pr-0 sm:text-center sm:pt-12"
                initial={{ x: -100, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Your gateway to bhutan's essence
              </motion.p>
            </div>
            <div className="w-1/2 flex flex-col pl-32 gap-[1rem] sm:w-full sm:pl-0">
              <div className="flex justify-between sm:justify-center sm:gap-2">
                <motion.img
                  src={F1}
                  alt=""
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
                <motion.img
                  src={F2}
                  alt=""
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 0.2 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
              </div>
              <div className="flex justify-between sm:justify-center sm:gap-2">
                <motion.img
                  src={F3}
                  alt=""
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
                <motion.img
                  src={F4}
                  alt=""
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 0.6 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
                <motion.img
                  src={F5}
                  alt=""
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 0.8 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
              </div>
              <div className="flex justify-between sm:justify-center sm:gap-2">
                <motion.img
                  src={F6}
                  alt=""
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 1 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
                <motion.img
                  src={F7}
                  alt=""
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 1.2 }}
                  onLoad={() => setImageLoad(false)}
                  loading="lazy"
                  className={imageLoad ? "shimmer" : "hover:scale-110 sm:h-20"}
                />
              </div>
            </div>
          </div>
          <div className="flex pt-20 gap-20 sm:gap-4 sm:pt-12 sm:flex-col">
            <img src={Chorten} alt="" className="sm:w-full sm:h-auto sm:object-cover"/>
            <div className="flex flex-col gap-8">
              <h1 className="font-bold uppercase text-7xl sm:text-xl">Druk Rewa</h1>
              <p className="font-thin text-2xl sm:text-lg">
                Why choose Druk Rewa Adventures, you ask? Well, let us unravel
                the mystery of this call from the East! Immersed in Bhutan's
                mystic essence, we are not just a travel agency. Think of us as
                your sherpa, your guru, your mystical guide to experiencing
                Bhutan’s true spirit.
              </p>
            </div>
          </div>
          <div className="pt-20">
            <h1 className="font-bold text-xl pb-4 sm:text-3xl sm:text-center">Local Expertise</h1>
            <p className="font-thin text-3xl w-1/2 sm:w-full sm:text-xl sm:text-center">
              Our squad of patriotic locals, with their intimate details of
              Bhutan's culture and landscapes, curates experiences that even
              Google can’t map out. We don’t just meet global standards—we set
              those standards!{" "}
            </p>
          </div>
          <div className="pt-20">
            <h1 className="font-bold text-5xl sm:text-3xl sm:text-center">Tailored Journeys</h1>
            <div className="flex w-full justify-between items-start gap-20 text-start font-thin text-2xl pt-8 sm:flex-col sm:text-xl">
              <p className="w-1/2 sm:w-full sm:px-6 sm:text-center">
                Every traveler is unique. And so should be the journey. At Druk
                Rewa Adventures, we chisel journeys that mirror your desires,
                crafting an authentic, comfy and discovery-laden adventure.
              </p>
              <p className="w-1/2 sm:w-full sm:text-center sm:px-6">
                Take a leap into the unknown—your perfect, unparalleled
                experience is just a booking away!
              </p>
            </div>
          </div>
          <div className="pt-20">
            <h1 className="font-bold text-5xl sm:text-center sm:text-3xl">Our Commitment to Success</h1>
            <div className="flex flex-col gap-20 pt-10 sm:flex-row sm:gap-8 sm:justify-center sm:px-6">
              <div className="grid grid-cols-3 gap-20 sm:flex sm:flex-col sm:w-[50%] sm:gap-8">
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">Meticulous Planning</h1>
                  <p className="font-thin text-lg sm:text-base">
                    We pay attention to every minute detail to craft the perfect
                    itinerary
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">Attentive Service</h1>
                  <p className="font-thin text-lg sm:text-base" >
                    Our dedicated team is committed to provide you with
                    impeccable service.
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">Exceeding Expectations</h1>
                  <p className="font-thin text-lg sm:text-base">
                    Our dedicated team is committed to provide you with
                    impeccable service.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-20 sm:flex sm:flex-col sm:gap-8">
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">Sustainable Tourism</h1>
                  <p className="font-thin text-lg sm:text-base">
                    Preserving Bhutan's breath-taking beauty is deeply rooted in
                    our ethos.
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">Minimal Footprints</h1>
                  <p className="font-thin text-lg sm:text-base">
                    Our travels leave a minimal environmental footprint, while
                    maximizing your adventure’s footprints on your heart.
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <h1 className="font-bold text-2xl sm:text-lg">
                    Transformative Experiences
                  </h1>
                  <p className="font-thin text-lg sm:text-base">
                    A voyage with Druk Rewa Adventures is a transformative
                    experience, where every moment is curated to create
                    cherished memories.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
          <div className="container mx-auto text-center">
            <h2 className="text-white text-6xl font-semibold mb-4 sm:text-2xl">
              Get Started
            </h2>
            <p className="text-white text-md mb-6 px-40 sm:text-sm sm:px-4">
              Ready for the ultimate Bhutan experience? Don’t just travel,
              experience the unexperienced, get drenched in the cultural deluge,
              join the joyride of discovery! Embark on a transformative journey
              with Druk Rewa Adventures.
            </p>
            <Link className="" to={"mailto:drukrewa@gmail.com"}>
              <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                <span className="hover:text-black relative z-[100]">
                  Book Now
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DrukRewa