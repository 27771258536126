import React from 'react'
import NavBarTwo from '../Components/NavBarTwo'
import certificate from "../assets/certificate.png"
import AnimatedText from '../Components/AnimatedText';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import travelife from "../assets/travelife.png"

const Sustainability = () => {
    const { scrollYProgress } = useViewportScroll();
    const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);

    return (
      <div className="sm:overflow-x-hidden">
        <NavBarTwo />
        <div className="buddha px-16 pt-[8.5rem] sm:px-4">
          <div className="px-32 py-20 bg-white sm:px-4">
            <h1 className="font-bold uppercase text-5xl text-center sm:text-2xl">
              <AnimatedText text={"Sustainability in Tourism"} />
            </h1>
            <div className="flex flex-col gap-8 pt-8">
              <motion.p
                className="font-thin text-xl sm:text-sm text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                Welcome to Druk Rewa Adventures, your gateway to the enchanting
                landscapes of Bhutan. We are proud to announce that we are a
                certified sustainability tourism agency, recognized by{" "}
                <Link
                  to={
                    "https://www.travelife.info/index_new.php?menu=home&lang=en#"
                  }
                  className="font-semibold hover:underline hover:underline-offset-1"
                >
                  travelife.info
                </Link>
                . Our commitment to sustainable tourism extends beyond just
                words; it's a promise we keep every day through our actions.
              </motion.p>
            </div>

            <div className="pt-10">
              <div className="w-full flex justify-center items-center pt-10">
                <img src={travelife} alt="" className="h-52 w-auto fade-in sm:h-32" />
              </div>
              <div className='py-4'>
                <p className='font-thin text-xl sm:text-sm sm:text-center'>
                  Travelife is a leading initiative for sustainable tourism
                  companies. By operating responsibly, businesses enhance
                  customer satisfaction, staff motivation, and business
                  efficiency. Companies committed to sustainability score better
                  in terms of customer satisfaction and competitive advantage.
                  Sustainability management involves consistent practices across
                  products, impact monitoring, and support for suppliers on
                  their sustainability journey.
                </p>
              </div>
            </div>
            <div className="w-full flex justify-center items-center relative pt-20">
              <motion.img
                src={certificate}
                alt=""
                className="h-[25rem] w-auto fade-in sm:h-[12rem]"
                style={{ scale }}
              />
            </div>
            <div className="pt-40">
              <h1 className="font-bold text-6xl uppercase sm:text-2xl sm:text-center ">What? </h1>
              <div className=" flex justify-between gap-20 items-start w-full pt-12 sm:gap-4 sm:flex-col">
                <motion.p className="w-1/2 text-xl font-thin rounded-md shadow-xl p-4 sm:w-full sm:shadow-none sm:text-base sm:text-center">
                  Sustainable tourism is a form of travel that considers the
                  current and future economic, social, and environmental impacts
                  by addressing the needs of its ecological surroundings and the
                  local communities.
                </motion.p>
                <p className="w-1/2 text-xl font-thin rounded-md shadow-inner p-4 sm:w-full sm:shadow-none sm:text-base sm:text-center">
                  It is achieved by protecting natural environments and wildlife
                  when developing and managing tourism activities, providing
                  only authentic experiences for tourists that don’t appropriate
                  or misrepresent local heritage and culture, and creating
                  direct socioeconomic benefits for local communities through
                  training and employment
                </p>
              </div>
            </div>
            <div className="pt-40">
              <h1 className="font-bold text-6xl uppercase text-end sm:text-center">Why? </h1>
              <div className=" flex justify-between gap-20 items-start w-full pt-12 sm:gap-4 sm:flex-col">
                <p className="w-1/2 text-xl font-thin sm:w-full sm:shadow-none sm:text-base sm:text-center">
                  It creates positive experiences for local people, travelers,
                  and the industry itself, thus meeting the needs of the present
                  without compromising the future
                </p>
                <p className="w-1/2 text-xl font-thin px-4 pb-4 rounded-md shadow-xl sm:w-full sm:shadow-none sm:text-base sm:text-center">
                  Sustainable tourism is important because it allows travel
                  destinations and organizations to meet the needs of travelers
                  while having a low impact on natural resources and generating
                  long-term employment for locals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default Sustainability