import { Disclosure } from "@headlessui/react";
import { IoIosArrowDropdown } from "react-icons/io";


const Accordion = ({ question, answer }) => {
  return (
    <Disclosure as="p" className="w-full py-4">
      {({ open }) => (
        <>
          <Disclosure.Button className="font-semibold text-xl sm:text-sm inline-flex items-center cursor-pointer justify-between text-start w-full mb-1 text-neutral-800">
            {question}{" "}
            <span
              className={`p-2 hover:bg-zinc-400/30 rounded-full ${
                open ? "rotate-180" : ""
              }`}
            >
              <IoIosArrowDropdown className="w-5 h-5" />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel
            className={`text-md transition-all duration-1000 overflow-hidden text-[#A2A9B0] ${
              open ? "max-h-[100vh]" : "max-h-0"
            }`}
          >
            {answer}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Accordion;
