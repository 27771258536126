import NavBarTwo from './../Components/NavBarTwo';
import ImageSlider from '../Components/ImageSlider';
import VisaContent from '../Components/VisaContent';
import Footer from '../Components/Footer';
import {Link} from "react-router-dom"

const Visa = () => {
  return (
    <div className='overflow-x-hidden'>
      <NavBarTwo />
      <div className="buddha px-16 pt-[8.5rem] sm:px-4">
        <div className="px-8 py-20 bg-white sm:px-4">
          <main className="">
            <h1 className="text-4xl font-bold text-center mb-4 uppercase sm:text-2xl">
              Visa & Entry Information
            </h1>
            <ImageSlider />
            <VisaContent />
          </main>
        </div>
        <div className="bg-gradient-to-b from-slate-900/[95%] via-gray-900 to-black text-white py-[5.6rem]">
          <div className="container mx-auto text-center">
            <h2 className="text-white text-4xl font-semibold mb-4 sm:text-xl">
              So start your trip with us right here right now
            </h2>
            <p className="text-white text-sm mb-6 sm:text-xs">
              Book your adventure now and explore Bhutan
            </p>
            <Link className="" to={"mailto:drukrewa@gmail.com"}>
              <button className="py-2 px-6 uppercase cta-button rounded-full border border-white">
                <span className="hover:text-black relative z-[100]">
                  Book Now
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Visa