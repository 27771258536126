import * as React from "react";
import { Rating, Dialog } from "@mui/material";
import {toast} from "react-toastify"
import { Link } from "react-router-dom";

export default function RatingModal({open, handleClose, initial, dbname}) {
  const [value, setValue] = React.useState(initial);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

const user = window.localStorage.getItem("user")

 const handleSubmit = async () => {
   try {
     const response = await fetch(`${process.env.REACT_APP_API}/reviews`, {
       // replace '/api/review' with your actual API endpoint
       method: "PUT",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         name: dbname,
         username: "admin",
         rating: value,
       }),
     });

     console.log ("dbname",dbname)
     const data = await response.json();
     console.log("Review updated successfully:", data);

     if(response.ok){
        toast.success("Thank you for the review!");
        console.log("line 31")
        
        window.setTimeout(() => {
            window.location.reload();
        }, 3000)
     }

   } catch (error) {
     console.error("Error:", error);
   }
 };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: "rgba(255,255,255,0.9)",
          backdropFilter: "blur(5px)",
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <h1 className="font-bold text-lg">
        {user === "logged in" ? "Rate Us" : "You aren't logged in!"}
      </h1>
      {user === "logged in" ? (
        <div className="flex flex-col gap-4">
          <Rating
            name="half-rating"
            precision={0.5}
            value={value}
            onChange={handleChange}
          />
          <button
            className="py-2 px-6 uppercase signup-button rounded-full border border-black"
            onClick={handleSubmit}
          >
            <span className={`hover:text-white relative z-[100] `}>Submit</span>
          </button>
        </div>
      ) : (
        <Link to={"/user/login"}>
          <button
            className="py-2 px-6 uppercase signup-button rounded-full border border-black"
          >
            <span className={`hover:text-white relative z-[100] `}>Log In</span>
          </button>
        </Link>
      )}
    </Dialog>
  );
}
