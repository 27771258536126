import Logo from "../assets/drukrewa.png"
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="buddha2 w-screen h-screen flex justify-center items-center flex-col gap-20">
      <div>
        <img src={Logo} alt="drukrewa logo" className="w-32 h-auto" />
      </div>
      <h1 className="font-bold text-white text-3xl uppercase">
        Couldn't find the page you requested for.
      </h1>
      <div className="flex justify-center items-center gap-4">
        <h1 className="text-white text-lg font-bold">Go Back to Home Page?</h1>
        <Link className="rounded-full border border-white" to={"/"}>
          <button
            className="py-2 px-6 uppercase cta-button"
          >
            <span
              className={`hover:text-black relative z-[100]`}
            >
              Home
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound