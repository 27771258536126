import NavBarTwo from './../Components/NavBarTwo';
import ContactForm from '../Components/ContactForm';
import Footer from '../Components/Footer';
const Contactus = () => {
  return (
    <div className='overflow-x-hidden'>
      <NavBarTwo />
      <div className="buddha px-16 pt-[12.5rem] flex flex-col gap-10 pb-10 sm:px-4">
        <ContactForm />
        <div className="flex w-full bg-white rounded-md p-4 sm:flex-col">
          <div className="flex flex-col w-1/3 p-4 gap-10 sm:gap-4 sm:w-full">
            <h1 className='font-bold text-4xl sm:text-2xl sm:text-center'>Address</h1>
            <div className="flex flex-col font-thin text-2xl gap-6 sm:text-lg sm:text-center">
              <p>Flat No.301, Building No 47A</p>
              <p>Norzin Lam, Thimphu</p>
              <p>Mobile: +975 17760015</p>
              <p>Email: drukrewa@gmail.com</p>
            </div>
          </div>
          <div className='flex justify-end items-end w-2/3 sm:w-full sm:pt-12'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5953.242857863298!2d89.63268339239448!3d27.474881896671477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19416e33e1cbf%3A0x12bfb57226813be0!2sDruk%20Rewa!5e0!3m2!1sen!2sbt!4v1702868806117!5m2!1sen!2sbt"
              width="600"
              height="400"
              style={{border:0}}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title='drukrewa map'
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contactus