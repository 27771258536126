import React from "react";

const VisaContent = () => {
  return (
    <>
      <div className="container mx-auto mt-8 p-4">
        <div className="bg-gray-100 p-4 rounded-md shadow-md">
          <p className="text-lg mb-4 sm:text-sm">
            <strong>Visa Information:</strong> Visa fee is $40 per person one
            time for a maximum stay of 30 days.
          </p>

          <p className="text-lg mb-4 sm:text-sm">
            If you travel to Bhutan, whichever country you are entering from and
            exiting to, please ensure you have valid visas to avoid immigration
            hassles. If you are entering from and exiting to India, ensure you
            have a multi-entry visa to India. Countries like Thailand and Nepal
            issue visas upon arrival to citizens of most countries. If you are
            using Singapore or Bangladesh as entry and exit countries, please
            check visa rules and regulations. You will find information in our
            Reminder to Guest.
          </p>

          <p className="text-lg sm:text-sm">
            <strong>Tariff Information:</strong> Every visitor to Bhutan from
            countries other than India will pay:
          </p>

          <p className="text-lg sm:text-sm">
            <strong>Sustainable Development Fee (SDF):</strong> USD 100 per
            night in Bhutan. The SDF DOES NOT take care of any cost/expense that
            will be incurred during the Tour. The SDF is applicable for tours
            till December 2027.
          </p>

          <p className="text-lg mt-4 sm:text-sm">
            For visitors from India, the SDF is{" "}
            <strong>Rs. 1200 per night.</strong>
          </p>

          <p className="text-lg mt-4 sm:text-sm">
            <strong>Children's Discount:</strong> Children between the ages of 5
            to 12 will be eligible for a 50% discount of the SDF.
          </p>

          <p className="text-lg mt-4 sm:text-sm" >
            <strong>Exemption:</strong> Children below the age of 5 will be
            exempt from paying the SDF.
          </p>
        </div>
      </div>
      <div className="container mx-auto mt-8 p-4">
        <div className="bg-gray-100 p-4 rounded-md shadow-md">
          <p className="text-lg mb-4 sm:text-sm">
            You can either purchase your airtickets online or we can book and
            purchase them for you. There is no added fee for this service.
          </p>

          <p className="text-lg sm:text-sm">
            <strong>Drukair:</strong> Government-run airline and flies between
            Paro and these countries:
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>India: New Delhi, Kolkata, Mumbai, Gauhati, Bagdogra, Gaya</li>
            <li>Bangladesh: Dhaka</li>
            <li>Thailand: Bangkok</li>
            <li>Singapore: Singapore</li>
            <li>Nepal: Kathmandu</li>
          </ul>

          <p className="text-lg mt-4 sm:text-sm">
            <strong>Domestic flights:</strong> to Bumthang, Gelephu, and
            Yongphula from Paro.
          </p>

          <p className="text-lg mt-4 sm:text-sm">
            <strong>Tashi Air/Bhutan Airlines:</strong> Private airline and
            flies between Paro and these countries:
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>India: New Delhi, Kolkata</li>
            <li>Thailand: Bangkok</li>
            <li>Nepal: Kathmandu</li>
          </ul>

          <p className="text-lg mt-4 sm:text-sm">
            <strong>Bhutan Helicopter Service:</strong> The Royal Bhutan
            Helicopter Services Ltd offers the following Points of Interest
            (POI) Scenic flights
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>15 minutes flight</li>
            <li>1. Paro Airport</li>
            <li>2. Paro Valley</li>
            <li>3. Paro Rinpung Dzong</li>
            <li>4. Ta Dzong</li>
            <li>5. Paro Takshang</li>
          </ul>

          <p className="text-lg mt-4 sm:text-sm">
            <strong>Overland entry and Exit:</strong> You also have the option
            of entering and exiting Bhutan from these border towns:
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Phuentsholing: South West</li>
            <li>Samdrup Jongkhar: closed</li>
            <li>Gelephu: closed</li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default VisaContent;
