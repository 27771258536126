import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Rooms = ({ onSearch, chatbotVisible }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="mt-[1.5rem] px-[5%]">
      <h1 className="text-[2rem] font-semibold">Wangchuk Dem's Homestay</h1>

      <div className="flex mt-1">
        <span className="flex justify-end items-center gap-2">
          {/* Star review svg */}
          <svg
            width="15"
            height="15"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.31769 14.2916C9.52733 14.1673 9.7881 14.1673 9.99774 14.2916L14.486 16.953C14.9922 17.2532 15.6106 16.7981 15.4747 16.2256L14.292 11.245C14.2341 11.0013 14.3178 10.7457 14.5086 10.5833L18.4371 7.23977C18.8886 6.85543 18.6513 6.11699 18.0603 6.06773L12.8661 5.63478C12.6209 5.61434 12.4068 5.46046 12.3094 5.23449L10.2698 0.506842C10.0383 -0.0299468 9.27715 -0.0299478 9.04558 0.506842L7.00607 5.23449C6.90859 5.46046 6.69456 5.61434 6.44931 5.63478L1.25508 6.06773C0.664128 6.11699 0.426781 6.85543 0.878371 7.23978L4.80687 10.5833C4.99764 10.7457 5.08129 11.0013 5.02341 11.245L3.84077 16.2256C3.70482 16.7981 4.32326 17.2532 4.82943 16.953L9.31769 14.2916Z"
              fill="#222222"
            />
          </svg>
          22 reviews
        </span>

        {/* location */}
        <div className="flex items-center gap-2 ml-4">
          <svg
            width="15"
            height="15"
            viewBox="0 0 19 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 0C4.26172 0 0 4.26172 0 9.5C0 16.5 9.5 25 9.5 25C9.5 25 19 16.5 19 9.5C19 4.26172 14.7383 0 9.5 0ZM9.5 13.2812C7.26172 13.2812 5.4375 11.457 5.4375 9.21875C5.4375 6.98047 7.26172 5.15625 9.5 5.15625C11.7383 5.15625 13.5625 6.98047 13.5625 9.21875C13.5625 11.457 11.7383 13.2812 9.5 13.2812Z"
              fill="#222222"
            />
          </svg>
          <p className="text-[#6b727f]">Thimphu, Bhutan</p>
        </div>
      </div>

      <div className="images flex w-full gap-1 overflow-hidden rounded-lg mt-[2.3rem]">
        <img
          src="https://timetomosey.com/wp-content/uploads/2019/nov-06-bhutan-17-day-country-tour/2019-11-nov-11-mon-punakha-gangtey/wangchuk-dem-village-homestay-gangtey/wangchuk-dem-village-homestay-gangtey-03.jpg"
          alt=""
          className="w-[50%]"
        />
        <div className="flex flex-col gap-1">
          <img
            src="https://timetomosey.com/wp-content/uploads/2019/nov-06-bhutan-17-day-country-tour/2019-11-nov-11-mon-punakha-gangtey/wangchuk-dem-village-homestay-gangtey/wangchuk-dem-village-homestay-gangtey-29.jpg"
            alt=""
            className="w-full"
          />
          <img
            src="https://timetomosey.com/wp-content/uploads/2019/nov-06-bhutan-17-day-country-tour/2019-11-nov-11-mon-punakha-gangtey/wangchuk-dem-village-homestay-gangtey/wangchuk-dem-village-homestay-gangtey-05.jpg"
            alt=""
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <img
            src="https://www.communitytourism.bt/wp-content/uploads/2017/12/w1.jpg"
            alt=""
            className="w-full"
          />
          <img
            src="https://q-xx.bstatic.com/xdata/images/hotel/max500/158599151.jpg?k=c0383f286538347665ef6ad430b6787a3a6518069998ca71c8bfb1d5768b6077&o="
            alt=""
            className="w-full"
          />
        </div>
      </div>
      <div
        className={`details flex mt-[2.3rem] ${
          chatbotVisible ? "flex-col" : ""
        }`}
      >
        <div className={`left ${chatbotVisible ? "w-[90%]" : "w-[60%]"}`}>
          <h1 className="text-[1.5rem] font-semibold">
            Entire place is looked after by Jigme Tobgay
          </h1>
          <div class="flex flex-col gap-1 mt-5 border-b border-[#e5e7eb] pb-[2rem] mb-[2.5rem]">
            <div class="flex justify-start gap-3">
              <p class="text-[#222222] relative flex items-center">
                <span class="w-1 h-1 bg-black rounded-full mr-2"></span> 4
                guests
              </p>
              <p class="text-[#222222] relative flex items-center">
                <span class="w-1 h-1 bg-black rounded-full mr-2"></span> 6
                bedrooms
              </p>
              <p class="text-[#222222] relative flex items-center">
                <span class="w-1 h-1 bg-black rounded-full mr-2"></span> 3 baths
              </p>
              <p class="text-[#222222] relative flex items-center">
                <span class="w-1 h-1 bg-black rounded-full mr-2"></span> 6 beds
              </p>
            </div>
          </div>

          {/* special facitlities */}
          <div className="facilities flex flex-col gap-5 border-b border-[#e5e7eb] pb-[2rem] mb-[2.5rem]">
            <div className="flex whitespace-nowrap gap-5 justify-start items-center">
              <div className="svg">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.31769 14.2916C9.52733 14.1673 9.7881 14.1673 9.99774 14.2916L14.486 16.953C14.9922 17.2532 15.6106 16.7981 15.4747 16.2256L14.292 11.245C14.2341 11.0013 14.3178 10.7457 14.5086 10.5833L18.4371 7.23977C18.8886 6.85543 18.6513 6.11699 18.0603 6.06773L12.8661 5.63478C12.6209 5.61434 12.4068 5.46046 12.3094 5.23449L10.2698 0.506842C10.0383 -0.0299468 9.27715 -0.0299478 9.04558 0.506842L7.00607 5.23449C6.90859 5.46046 6.69456 5.61434 6.44931 5.63478L1.25508 6.06773C0.664128 6.11699 0.426781 6.85543 0.878371 7.23978L4.80687 10.5833C4.99764 10.7457 5.08129 11.0013 5.02341 11.245L3.84077 16.2256C3.70482 16.7981 4.32326 17.2532 4.82943 16.953L9.31769 14.2916Z"
                    fill="#222222"
                  />
                </svg>
              </div>
              <div className="perkdesc">
                <h1 className="text-[#222222] font-semibold">Entire place</h1>
                <p className="text-[#6b727f]">
                  You’ll have the place to yourself.
                </p>
              </div>
            </div>
            <div className="flex whitespace-nowrap gap-5 justify-start items-center">
              <div className="svg">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.31769 14.2916C9.52733 14.1673 9.7881 14.1673 9.99774 14.2916L14.486 16.953C14.9922 17.2532 15.6106 16.7981 15.4747 16.2256L14.292 11.245C14.2341 11.0013 14.3178 10.7457 14.5086 10.5833L18.4371 7.23977C18.8886 6.85543 18.6513 6.11699 18.0603 6.06773L12.8661 5.63478C12.6209 5.61434 12.4068 5.46046 12.3094 5.23449L10.2698 0.506842C10.0383 -0.0299468 9.27715 -0.0299478 9.04558 0.506842L7.00607 5.23449C6.90859 5.46046 6.69456 5.61434 6.44931 5.63478L1.25508 6.06773C0.664128 6.11699 0.426781 6.85543 0.878371 7.23978L4.80687 10.5833C4.99764 10.7457 5.08129 11.0013 5.02341 11.245L3.84077 16.2256C3.70482 16.7981 4.32326 17.2532 4.82943 16.953L9.31769 14.2916Z"
                    fill="#222222"
                  />
                </svg>
              </div>
              <div className="perkdesc">
                <h1 className="text-[#222222] font-semibold">Enhanced Clean</h1>
                <p className="text-[#6b727f]">
                  This Host committed to 5-step enhanced cleaning process.
                </p>
              </div>
            </div>
            <div className="flex whitespace-nowrap gap-5 justify-start items-center">
              <div className="svg">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.31769 14.2916C9.52733 14.1673 9.7881 14.1673 9.99774 14.2916L14.486 16.953C14.9922 17.2532 15.6106 16.7981 15.4747 16.2256L14.292 11.245C14.2341 11.0013 14.3178 10.7457 14.5086 10.5833L18.4371 7.23977C18.8886 6.85543 18.6513 6.11699 18.0603 6.06773L12.8661 5.63478C12.6209 5.61434 12.4068 5.46046 12.3094 5.23449L10.2698 0.506842C10.0383 -0.0299468 9.27715 -0.0299478 9.04558 0.506842L7.00607 5.23449C6.90859 5.46046 6.69456 5.61434 6.44931 5.63478L1.25508 6.06773C0.664128 6.11699 0.426781 6.85543 0.878371 7.23978L4.80687 10.5833C4.99764 10.7457 5.08129 11.0013 5.02341 11.245L3.84077 16.2256C3.70482 16.7981 4.32326 17.2532 4.82943 16.953L9.31769 14.2916Z"
                    fill="#222222"
                  />
                </svg>
              </div>
              <div className="perkdesc">
                <h1 className="text-[#222222] font-semibold">Perfect View</h1>
                <p className="text-[#6b727f]">
                  The view here is absolutely stunning.
                </p>
              </div>
            </div>
          </div>

          <div
            className={`overview transition-all ease-in-out duration-300 ${
              showMore ? "line-clamp-none" : "line-clamp-4"
            }`}
          >
            <p className={`text-[#222222] mt-3`}>
              Wangchuk Dem Homestay in Phobjikha, Bhutan, offers a serene escape
              amidst the captivating beauty of Phobjikha Valley. Nestled in the
              heart of the valley, this charming homestay provides comfortable
              accommodation with traditional Bhutanese decor and stunning valley
              views. Guests can savor authentic Bhutanese cuisine and embark on
              nature walks to witness the endangered black-necked cranes or
              explore nearby monasteries. The hosts' warm Bhutanese hospitality
              and opportunities for stargazing under the pristine night sky make
              Wangchuk Dem Homestay a perfect blend of nature, culture, and
              tranquility for a memorable Bhutanese experience.
            </p>
          </div>
          {!showMore && (
            <button
              className="text-black underline mt-2 cursor-pointer"
              onClick={toggleShowMore}
            >
              Show More
            </button>
          )}
          {showMore && (
            <button
              className="text-black underline mt-2 cursor-pointer"
              onClick={toggleShowMore}
            >
              Show Less
            </button>
          )}
        </div>

        <div
          className={`right checkout  ${
            chatbotVisible ? "w-[70%] mt-10" : "w-[40%] ml-[10%]"
          }`}
        >
          <div className="flex flex-col gap-8 shadow-lg p-[2rem] py-[1.5rem] rounded-lg">
            <div className="heading flex justify-between items-end">
              <div className="price flex items-end">
                <h1 className="text-[1.5rem] font-semibold">Nu.1000</h1>
                <p className="text-[#6b727f]"> / night</p>
              </div>
              <div className="reviews">
                <div className="flex items-center gap-2">
                  {/* Star review svg */}
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.31769 14.2916C9.52733 14.1673 9.7881 14.1673 9.99774 14.2916L14.486 16.953C14.9922 17.2532 15.6106 16.7981 15.4747 16.2256L14.292 11.245C14.2341 11.0013 14.3178 10.7457 14.5086 10.5833L18.4371 7.23977C18.8886 6.85543 18.6513 6.11699 18.0603 6.06773L12.8661 5.63478C12.6209 5.61434 12.4068 5.46046 12.3094 5.23449L10.2698 0.506842C10.0383 -0.0299468 9.27715 -0.0299478 9.04558 0.506842L7.00607 5.23449C6.90859 5.46046 6.69456 5.61434 6.44931 5.63478L1.25508 6.06773C0.664128 6.11699 0.426781 6.85543 0.878371 7.23978L4.80687 10.5833C4.99764 10.7457 5.08129 11.0013 5.02341 11.245L3.84077 16.2256C3.70482 16.7981 4.32326 17.2532 4.82943 16.953L9.31769 14.2916Z"
                      fill="#222222"
                    />
                  </svg>
                  <p className="text-[#222222] underline cursor-pointer">
                    24 reviews
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col border border-gray-300 rounded-lg">
              <div className="flex justify-between items-start w-full border-b border-gray-300">
                <div className="w-full border-r border-gray-300 p-3">
                  <h3 className="uppercase font-bold text-[0.8rem]">
                    Check-In
                  </h3>
                  <p className="text-[#6b727f]">12/02/2023</p>
                </div>
                <div className="w-full p-3">
                  <h3 className="uppercase font-bold text-[0.8rem]">
                    Check-Out
                  </h3>
                  <p className="text-[#6b727f]">16/02/2023</p>
                </div>
              </div>
              <div className="w-full border-b border-gray-300 flex justify-between">
                <div className="w-full p-3">
                  <h3 className="uppercase font-bold text-[0.8rem]">Guests</h3>
                  <p className="text-[#6b727f]">2 Guests</p>
                </div>
                {/* add or minus option */}
                <div className="flex gap-2 items-center mr-3">
                  <button className="w-8 h-8 bg-[#f7f7f7] rounded-lg flex justify-center items-center hover:bg-[#e2e2e2]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 inline-block mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  </button>
                  <button className="w-8 h-8 bg-[#f7f7f7] rounded-lg flex justify-center items-center hover:bg-[#e2e2e2]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 inline-block mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20 12H4"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="reserve_button">
              <Link to="/room/id/payment">
                <button className="w-full tracking-wider py-3 bg-black text-white rounded-lg transition duration-300 ease-in-out transform hover:bg-black/80 active:scale-[95%]">
                  Reserve
                </button>
              </Link>
            </div>

            <div className="bill flex flex-col gap-1">
              <div className="nights flex justify-between items-center">
                <p className="text-[#222222]">Nu.1000 x 4 nights</p>
                <p className="text-[#222222] text-right">Nu.4000</p>
              </div>
              <div className="services flex justify-between items-center">
                <p className="text-[#222222]">Service Fee</p>
                <p className="text-[#222222] text-right">Nu.200</p>
              </div>
              <div className="cleaning flex justify-between items-center">
                <p className="text-[#222222]">Cleaning Fee</p>
                <p className="text-[#222222] text-right">Nu.99</p>
              </div>
              <div className="taxes flex justify-between items-center">
                <p className="text-[#222222]">Occupancy taxes and fees</p>
                <p className="text-[#222222] text-right">Nu.290</p>
              </div>
              <div className="discount flex justify-between items-center">
                <p className="text-[#222222]">Monthly Discount</p>
                <p className="text-[#10b880] text-right">-Nu.290</p>
              </div>

              {/* Total */}

              <div className="total flex justify-between items-center mt-5 border-t pt-3 border-gray-300 text-[1.1rem]">
                <p className="text-[#222222] font-semibold">Total</p>
                <p className="text-[#222222] text-right font-semibold">
                  Nu.4299
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
