import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect,useState } from "react";

const LoadingPage = () => {
//   const words = ["DRUK", "REWA", "ADVENTURES"];
const words = ["D", "R", "U", "K", " ","R", "E", "W", "A", " ","A", "D", "V", "E", "N", "T", "U", "R", "E", "S"]
const [showLoadingMessage, setShowLoadingMessage] = useState(false)
useEffect(() => {
  window.setTimeout(() => {
setShowLoadingMessage(true)
  }, 3000)
})


  return (
    <motion.div className="flex justify-center items-center h-screen w-screen">
      {words.map((word, index) => (
        <motion.span
          key={index}
          className="typography pl-4"
          initial={{ opacity: 0, x: 0.5 }}
          animate={{ opacity: 1, x: 50 }}
          transition={{ duration: 2, delay: index * 0.05 }}
        >
          {word}
        </motion.span>
      ))}
      {showLoadingMessage && (
        <motion.div className="absolute bottom-0 right-0 p-12 flex justify-center items-center gap-4"
        initial={{ opacity: 0, y: 0.5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2, delay: 0 }}
        >
          <CircularProgress />
          <p className="font-thin text-md">
            Please wait for a while as the assets of the website are loading.
            <p className="font-semibold">It may take a while depending on your internet speed.</p>
          </p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default LoadingPage;
